import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'

// import background from '../assets/images/campaign1/bg.jpg';
// import header from '../assets/images/campaign1/header.png';
// import bar from '../assets/images/campaign1/bar.png';
// import target from '../assets/images/campaign1/target.png';
// import target1000 from '../assets/images/campaign1/04-2_Target.png';
import approved from '../assets/images/campaign1/bullet-green.png';
import pending from '../assets/images/campaign1/bullet-yellow.png';


function Team(props) {
    const barRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    const [barYellow, setBarYellow] = useState(0)
    const [appBar, setAppBar] = useState(0)

    const { dataCampaign } = props;
    const dataTeam = dataCampaign.campaign.team
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataTeam.target;
    const userTarget = dataUser.target;
    // const userTarget = [2, 3, 4, 10, 12];
    // const userChecked = dataUser.checked;
    // const userPending = dataUser.pending;

    // Images data
    const background = dataTeam.background_picture;
    const header = dataTeam.headline_picture;
    const bar = dataTeam.progressbar_picture;

    // User data
    // const barGreen = dataUser;


    // const textStyle = (numTextPosition) => {
    //     const textPosition = numTextPosition;

    //     // const xxxx = textPosition;
    //     const txtColor = '#'+dataTeam[textPosition+'_color'];
    //     const txtSize = dataTeam[textPosition+'_size']+'rem';
    //     const txtShadowWidth = dataTeam[textPosition+'_shadow_width']+'px';
    //     const txtShadowColor = '#'+dataTeam[textPosition+'_shadow_color'];
    //     const txtStrokeWidth = dataTeam[textPosition+'_stroke_width']+'px';
    //     const txtStrokeColor = '#'+dataTeam[textPosition+'_stroke_color'];

    //     const objStyle = {
    //         color: txtColor,
    //         textShadow: txtShadowWidth+' '+txtShadowWidth+' '+txtShadowWidth+' '+txtShadowColor,
    //         fontSize: txtSize,
    //         WebkitTextStroke: txtStrokeWidth+' '+txtStrokeColor,
    //     }
    //     return objStyle;
    // }




    const checkTargetPos = useCallback(() => {
        // console.log('check position')
        const barWidth = barRef.current.clientWidth;
        const targetLength = campaignTarget.length;
        const targetPosition = Math.floor(barWidth / targetLength);

        setTargetPos(targetPosition);
    },[campaignTarget.length]);

    const calcBarPos = useCallback(() => {
        // let userTargetAll = userTarget.reduce(function(acc, val) { return acc + val; }, 0)
        const pb_length = barRef.current.clientWidth;
        const num_target = userTarget.length;
        let sec_length =  pb_length/num_target;
        let approved = dataUser.checked;
        let unapproved = dataUser.pending;
        // let approved = 5;
        // let unapproved = 5;
        
        let paint_length = 0;
        let color = "green";
        for(let ind = 0; ind < num_target; ind++) {
            // console.warn('userTarget',userTarget[ind])
            let userTargetPrev = (userTarget[ind-1] ? userTarget[ind-1] : 0);
            let userTargetNext = (userTarget[ind+1] ? userTarget[ind+1] : 0);
            let userTargetCurrent = userTarget[ind];

            if( approved > userTarget[ind]) {
                // Paint full bar : green
                setBarGreen(Math.abs((ind+1)*sec_length));
                // console.warn('>>>>>>>> full <<<<<<<< ROUND:',ind)
            } else { 
                // console.warn('>>>>>>>> half <<<<<<<< ROUND:',ind)
                // Paint bar : green
                paint_length = ((approved - userTargetPrev) * sec_length / (userTargetCurrent - userTargetPrev));
                setBarGreen(Math.abs((ind*sec_length) + paint_length));

                if(color === 'yellow') {
                    break;
                } else {
                    color = 'yellow';

                    approved += unapproved  ;  // บวกค่าที่รอตรวจเข้าไปกับค่าที่ตรวจแล้ว 

                    let barYellowCurrent = 0;
                    let barYellowNext = 0;
                    let unapprovedBalance = unapproved - (approved - userTargetCurrent );
// console.log('approved ', approved)
// console.log('userTargetPrev ', userTargetPrev)
// console.log('userTargetCurrent ', userTargetCurrent)
// console.log('userTargetNext ', userTargetNext)
// console.log('unapprovedBalance = ', unapprovedBalance)
                    if(approved > userTargetCurrent) {
                        barYellowCurrent = Math.abs(unapprovedBalance) * Math.abs(sec_length / (userTargetCurrent - userTargetPrev));
                        barYellowNext =  Math.abs(approved - (userTargetCurrent ))  * Math.abs(sec_length / (userTargetNext - userTargetCurrent));
                        // paint_length = barYellowNext

// console.log('_barYellowNext ', barYellowNext)
// console.log('_barYellowCurrent',barYellowCurrent)
                    } else {
                        barYellowCurrent = Math.abs(unapproved) * (sec_length / (userTargetCurrent - userTargetPrev));
                        
                        // paint_length = barYellowCurrent

// console.log('*barYellowNext ', barYellowNext)
// console.log('*barYellowCurrent',barYellowCurrent)
                    }

                    paint_length = barYellowCurrent + barYellowNext
                    setBarYellow(paint_length)
                }

                
                break;
            } 
        }
        
        // console.log('barWidth', pb_length,'px -------------')
        // console.log('barGreen', barGreen,'px')
        // console.log('barYellow', barYellow,'px')
    },[dataUser.checked, dataUser.pending, userTarget])
    
    useEffect(() => {
        // console.log(barRef)
        // console.log(barRef.current.clientWidth)
        setAppBar(barRef.current.clientWidth)
        
        // setIsLoaded(true)
        // console.log('barRef : ',barRef.current.clientWidth);
        checkTargetPos();
        // console.log(dataCampaign)
        // console.log(userTarget[0])
        calcBarPos();
    }, [barGreen, barYellow, calcBarPos, campaignTarget.length, checkTargetPos, dataUser.checked, dataUser.pending, userTarget])

    
    // Check Resize
    useLayoutEffect(() => {
        window.addEventListener('resize', checkTargetPos );
        window.addEventListener('resize', calcBarPos);
        checkTargetPos();
        calcBarPos();
        return () => {window.removeEventListener('resize', checkTargetPos); window.removeEventListener('resize', checkTargetPos);};
    }, [calcBarPos, checkTargetPos])


    if(campaignTarget.length === userTarget.length) {
        return (
            <div className="App-Team">
                <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                    <header className="App-header">
                        <img src={header} className="App-logo" alt="logo" />
                    </header>
                    <div className="App-content">
                        <React.Fragment>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text1{', 
                                        'font-size: '+dataTeam.text1_size+'rem;',
                                        'color: #'+dataTeam.text1_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text1:before {',
                                        'text-shadow: '+dataTeam.text1_shadow_width+'px '+dataTeam.text1_shadow_width+'px '+dataTeam.text1_shadow_width+'px #'+dataTeam.text1_shadow_color+';',
                                    '}',
                                    '.text1:before { content: "'+(dataTeam.text1_content)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text1_stroke_width+'px #'+dataTeam.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text2{', 
                                        'font-size: '+dataTeam.text2_size+'rem;',
                                        'color: #'+dataTeam.text2_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text2:before {',
                                        'text-shadow: '+dataTeam.text2_shadow_width+'px '+dataTeam.text2_shadow_width+'px '+dataTeam.text2_shadow_width+'px #'+dataTeam.text2_shadow_color+';',
                                    '}',
                                    // '.text2._1:before { content: "'+(userTarget[0])+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text2_stroke_width+'px #'+dataTeam.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text2._2:before { content: "'+(campaignTarget[0].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text2_stroke_width+'px #'+dataTeam.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text2._3:before { content: "'+(campaignTarget[0].reward_name)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text2_stroke_width+'px #'+dataTeam.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text2._4:before { content: "คะแนน"; position: absolute; -webkit-text-stroke: '+dataTeam.text2_stroke_width+'px #'+dataTeam.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text3{', 
                                        'font-size: '+dataTeam.text3_size+'rem;',
                                        'color: #'+dataTeam.text3_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text3:before {',
                                        'text-shadow: '+dataTeam.text3_shadow_width+'px '+dataTeam.text3_shadow_width+'px '+dataTeam.text3_shadow_width+'px #'+dataTeam.text3_shadow_color+';',
                                    '}',
                                    '.text3:before { content: "'+(dataTeam.text3_content)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text3_stroke_width+'px #'+dataTeam.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text4{', 
                                        'font-size: '+dataTeam.text4_size+'rem;',
                                        'color: #'+dataTeam.text4_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text4:before {',
                                        'text-shadow: '+dataTeam.text4_shadow_width+'px '+dataTeam.text4_shadow_width+'px '+dataTeam.text4_shadow_width+'px #'+dataTeam.text4_shadow_color+';',
                                    '}',
                                    '.text4:before { content: "'+(dataTeam.text4_content)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text4_stroke_width+'px #'+dataTeam.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text5{', 
                                        'font-size: '+dataTeam.text5_size+'rem;',
                                        'color: #'+dataTeam.text5_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text5:before {',
                                        'text-shadow: '+dataTeam.text5_shadow_width+'px '+dataTeam.text5_shadow_width+'px '+dataTeam.text5_shadow_width+'px #'+dataTeam.text5_shadow_color+';',
                                    '}',
                                    '.text5:before { content: "'+(userTarget[0])+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text5_stroke_width+'px #'+dataTeam.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text6{', 
                                        'font-size: '+dataTeam.text6_size+'rem;',
                                        'color: #'+dataTeam.text6_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text6:before {',
                                        'text-shadow: '+dataTeam.text6_shadow_width+'px '+dataTeam.text6_shadow_width+'px '+dataTeam.text6_shadow_width+'px #'+dataTeam.text6_shadow_color+';',
                                    '}',
                                    '.text6:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataTeam.text6_stroke_width+'px #'+dataTeam.text6_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text7{', 
                                        'font-size: '+dataTeam.text7_size+'rem;',
                                        'color: #'+dataTeam.text7_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0; word-break: keep-all;}',
                                    '.text7:before {',
                                        'text-shadow: '+dataTeam.text7_shadow_width+'px '+dataTeam.text7_shadow_width+'px '+dataTeam.text7_shadow_width+'px #'+dataTeam.text7_shadow_color+';',
                                    '}',
                                    '.text7:before { content: "'+(dataTeam.text7_content)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text7_stroke_width+'px #'+dataTeam.text7_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    // Responsive CSS
                                    '@media screen and (max-width: 374px) {',
                                        '.text1{', 
                                            'font-size: '+(dataTeam.text1_size - .2)+'rem;',
                                        '}',
                                        '.text2{', 
                                            'font-size: '+(dataTeam.text2_size - .2)+'rem;',
                                        '}',
                                        '.text3{', 
                                            'font-size: '+(dataTeam.text3_size - .2)+'rem;',
                                        '}',
                                        '.text4{', 
                                            'font-size: '+(dataTeam.text4_size - .2)+'rem;',
                                        '}',
                                        '.text5{', 
                                            'font-size: '+(dataTeam.text5_size - .2)+'rem;',
                                        '}',
                                        '.text6{', 
                                            'font-size: '+(dataTeam.text6_size - .2)+'rem;',
                                        '}',
                                        '.text7{', 
                                            'font-size: '+(dataTeam.text7_size - .2)+'rem;',
                                        '}',
                                    '}',
                                    ].join('\n')
                                    }}>
                            </style>
                            {/* <p style={{marginLeft: appBar /3+'px', textAlign: 'left'}}> */}
                            <p>
                                <span className="text1">{dataTeam.text1_content}</span>&nbsp;
                                {
                                    (campaignTarget[0].reward_type === 'sfdc') ? 
                                    <React.Fragment>
                                        <span className='text2 _2'>{(campaignTarget[0].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0)} </span>&nbsp;
                                        <span className='text2 _4'>คะแนน</span> 
                                    </React.Fragment>
                                    : 
                                    <span className='text2 _3'>
                                        { campaignTarget[0].reward_name }
                                    </span>
                                }
                                &nbsp;<span className="text3">{dataTeam.text3_content}</span>
                            </p>
                            {/* <p style={{marginLeft: appBar /3 +'px', textAlign: 'left'}}> */}
                            <p>
                                <span className="text4">{dataTeam.text4_content}</span>&nbsp;
                                <span className="text5">{userTarget[0]}</span>&nbsp;
                                <span className="text6">แถว</span>
                            </p>
                            <p>
                                <span className="text7">{dataTeam.text7_content}</span>
                            </p>
                        </React.Fragment>
                    </div>
                    {
                        (dataUser.text_extra) ? 
                            <div className="App-text-extra">
                                <style dangerouslySetInnerHTML={{
                                    __html: [
                                        '.textx{', 
                                            'font-size: '+dataTeam.textx_size+'rem;',
                                            'color: #'+dataTeam.textx_color+';',
                                            'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                        '.textx:before {',
                                            'text-shadow: '+dataTeam.textx_shadow_width+'px '+dataTeam.textx_shadow_width+'px '+dataTeam.textx_shadow_width+'px #'+dataTeam.textx_shadow_color+';',
                                        '}',
                                        '.textx:before { content: "'+(dataUser.text_extra)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.textx_stroke_width+'px #'+dataTeam.textx_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                        ].join('\n')
                                    }}>
                                </style>
                                <span className="textx">{dataUser.text_extra}</span>
                            </div>
                        : null
                    }
                <div style={{padding: '0 5%'}}   >
                    <div className="App-bar" id="app-bar" ref={barRef}>
                        <div className="App-bar-stick">
                            <div className="round-stick">
                                <div className="bar-group bar-group">
                                    <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                    <div id="bar-stick-yellow"  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                </div>
                                {/* {
                                    
                                    userTarget.map((item,i)=>{
                                        return(
                                            <div className={'bar-group bar-group-'+(i)} key={i}>
                                                <div id={'bar-stick-green-'+i} className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                                <div id={'bar-stick-yellow-'+i}  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                            </div>)
                                        }
                                    )
                                } */}
                            </div>
                        </div>
                        <img src={bar} className="App-bar-border" alt="bar" />
                        {
                            campaignTarget.map((item,i)=>{
                                return (
                                    <div className="App-target-box" style={{left: targetPos * (i+1), width: appBar / 2.4, height: appBar / 2.4,}} key={i} >{
                                            (dataUser.checked >= dataUser.target[i]) ? 
                                                <img src={item.target_picture_reached} className="App-target" alt="target-reached" />
                                            :
                                                <img src={item.target_picture_not_reached} className="App-target" alt="target-not-reached" />   
                                        }
                                        
                                        {/* <img src={target1000} className="App-target" alt="target" /> */}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                    <div className="App-status">
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text8{', 
                                    'font-size: '+dataTeam.text8_size+'rem;',
                                    'color: #'+dataTeam.text8_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text8:before {',
                                    'text-shadow: '+dataTeam.text8_shadow_width+'px '+dataTeam.text8_shadow_width+'px '+dataTeam.text8_shadow_width+'px #'+dataTeam.text8_shadow_color+';',
                                '}',
                                '.text8._1:before { content: "ตรวจแล้ว"; position: absolute; -webkit-text-stroke: '+dataTeam.text8_stroke_width+'px #'+dataTeam.text8_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text8._2:before { content: " แถว"; position: absolute; -webkit-text-stroke: '+dataTeam.text8_stroke_width+'px #'+dataTeam.text8_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text8._3:before { content: "รอการตรวจ"; position: absolute; -webkit-text-stroke: '+dataTeam.text8_stroke_width+'px #'+dataTeam.text8_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text8._4:before { content: " แถว"; position: absolute; -webkit-text-stroke: '+dataTeam.text8_stroke_width+'px #'+dataTeam.text8_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    
                                '.text9{', 
                                    'font-size: '+dataTeam.text9_size+'rem;',
                                    'color: #'+dataTeam.text9_color+';',
                                    'text-shadow: '+dataTeam.text9_shadow_width+'px '+dataTeam.text9_shadow_width+'px '+dataTeam.text9_shadow_width+'px #'+dataTeam.text9_shadow_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text9:before {',
                                    'text-shadow: '+dataTeam.text9_shadow_width+'px '+dataTeam.text9_shadow_width+'px '+dataTeam.text9_shadow_width+'px #'+dataTeam.text9_shadow_color+';',
                                '}',
                                '.text9._1:before { content: "'+(dataUser.checked)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text9_stroke_width+'px #'+dataTeam.text9_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text9._2:before { content: "'+(dataUser.pending)+'"; position: absolute; -webkit-text-stroke: '+dataTeam.text9_stroke_width+'px #'+dataTeam.text9_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                // Responsive CSS
                                '@media screen and (max-width: 374px) {',
                                    '.text8, .text9{', 
                                        'font-size: '+(dataTeam.text8_size - .2)+'rem;',
                                    '}',
                                    '.text9{', 
                                        'font-size: '+(dataTeam.text9_size - .2)+'rem;',
                                    '}',
                                '}',
                                ].join('\n')
                            }}>
                        </style>
                        <div className="status-content">
                            <img src={approved} className="status-bullet" alt="approved" />
                            <span className="text8 _1">ตรวจแล้ว</span>&nbsp;
                            <span className="text9 _1">{dataUser.checked}</span>&nbsp;
                            <span className="text8 _2"> แถว</span>
                        </div>
                        <div className="status-content">
                            <img src={pending} className="status-bullet" alt="pending" />
                            <span className="text8 _3">รอการตรวจ</span>&nbsp;
                            <span className="text9 _2">{dataUser.pending}</span>&nbsp;
                            <span className="text8 _4"> แถว</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        <div className="App-error">
          <p>ข้อมูลระหว่าง campaign และ user ไม่ตรงกัน</p>
        </div>
    }

}

export default Team
