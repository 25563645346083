import React, { useState } from 'react'
import moment from 'moment'

function Leader2(props) {
    const [hasError, setHasError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    const [dataLeaderboard, setDataLeaderboard] = useState(props.dataCampaign.campaign?.leaderboard)
    // const [dataUser, setDataUser] = useState({})
    const [dataTop, setDataTop] = useState(props.dataCampaign.top)
    const [dataPeriod, setDataPeriod] = useState(props.dataCampaign.campaign?.leaderboard.period)
    const [dataCurrentPeriod, setDataCurrentPeriod] = useState(props.dataCampaign.current_period || 1)
    
    // User data
    const [userRank, setUserRank] = useState(props.dataCampaign.user?.rank)
    const [userName, setUserName] = useState(props.dataCampaign.user?.name)
    const [userProgress, setUserProgress] = useState(props.dataCampaign.user?.progress)

    // Images data
    const [banner, setBanner] = useState(props.dataCampaign.campaign?.leaderboard.background_picture)
    const [updateTime, setUpdateTime] = useState(props.dataCampaign.user?.updated_time)
    const [background/*, setBackground*/] = useState(props.dataCampaign.campaign?.leaderboard.page_background_picture)

    // Target data
    // const target = dataLeaderboard.target;
    

    const getTableRow = () => {
        let resultArr = [];
        for (let i = 0; i < dataTop?.length; i++) {
            let item = dataTop[i];
            if(i === 0) {
                resultArr.push(
                    <tr key={i}>
                        <td>{item.rank}</td>
                        <td>{item.name}</td>
                        <td>{item.progress}</td>
                        {/* <td>{item.reward_text}</td> */}
                    </tr>
                )
            } else if(i === 1) {
                resultArr.push(
                    <tr key={i}>
                        <td>{item.rank}</td>
                        <td>{item.name}</td>
                        <td>{item.progress}</td>
                        {/* <td>{item.reward_text}</td> */}
                    </tr>
                )
            } else if(i === 2) {
                resultArr.push(
                    <tr key={i}>
                        <td>{item.rank}</td>
                        <td>{item.name}</td>
                        <td>{item.progress}</td>
                        {/* <td>{item.reward_text}</td> */}
                    </tr>
                )
            } else {
                resultArr.push(
                    <tr key={i}>
                        <td>{item.rank}</td>
                        <td>{item.name}</td>
                        <td>{item.progress}</td>
                        {/* <td>{item.reward_text}</td> */}
                    </tr>
                )
            }
        }

        return resultArr;
    }

    var pathArray = window.location.pathname.split('/')[1] || '';
    // console.log('leaderboard type: ',pathArray)

    const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']
    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' '+dataTime)
    }

    const convertStartDate = (valStartDate, valEndDate) => {
        const dataDate = moment(valStartDate).format('D')
        const dataMonth = Number(moment(valStartDate).format('MM'))
        const dataStartYear = (Number(moment(valStartDate).format('YYYY')) + 543)
        const dataEndYear = (Number(moment(valEndDate).format('YYYY')) + 543)

        if(dataStartYear !== dataEndYear) {
            return (dataDate+' '+monthArr[dataMonth -1]+' '+dataStartYear)
        } else {
            return (dataDate+' '+monthArr[dataMonth -1])
        }
    }

    const convertEndDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543)
        
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear)
    }


    const changePeriod = (val) => {
        // Get query
        const url_string = window.location.href;
        const url = new URL(url_string);
        const query = url.searchParams.get("query");
        const urlPathname = url.pathname.split('/');
        const idFromPathname = urlPathname[urlPathname.length -1];

        // Fetch API
        async function fetchDataCampaign (valPeriod) {
            setIsLoaded(false);

            const queryData = query;
            const queryFinal = queryData.split(' ').join('%2B');
            const campaignID = idFromPathname;
            const apiXKey = 'b5e64ba6-1d27-4c40-bc65-2e686e394d62';
    
            const apiPath = process.env.REACT_APP_API

            const res = await fetch(`${apiPath}`, {
                method: 'POST',
                body: JSON.stringify({
                    "query": queryFinal,
                    "campaign_id": campaignID,
                    "period": valPeriod
                }),
                headers: {
                    "x-application-secret-key": apiXKey,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }); 
    
            res
            .json()
            .then(res => {
                if (res.success === 0 || res === null || res === undefined ) {
                    setIsLoaded(true);
                    setHasError(true);
                }
                
                let dataCampaign = res // from API

                setIsLoaded(true);

                setDataLeaderboard(dataCampaign.campaign?.leaderboard)
                // setDataUser(res.user)
                setDataTop(dataCampaign.top)
                setDataPeriod(dataCampaign.campaign?.leaderboard.period)
                setDataCurrentPeriod(dataCampaign.current_period)

                setUserRank(dataCampaign.user?.rank)
                setUserName(dataCampaign.user?.name)
                setUserProgress(dataCampaign.user?.progress)

                setBanner(dataCampaign.campaign?.leaderboard.background_picture)
                setUpdateTime(dataCampaign.user?.updated_time)
            })
            .catch(err => {
                console.log(err);
                setIsLoaded(true);
                setHasError(true);
            });
        }

        fetchDataCampaign(val)
    }

    const getSelectPeriod = () => {
        let optionItem = dataPeriod?.map((item,i)=> {
            let startDate = convertStartDate(item.start_date, item.end_date)
            let endDate = convertEndDate(item.end_date)

            return <option value={i+1} key={i}>{startDate} - {endDate}</option>
        })
        
        return (
            <select value={dataCurrentPeriod} onChange={(e)=> changePeriod(e.target.value) }>
                { optionItem }
            </select>
        )
    }

    return (
        <div className="App-leaderboard-V2" style={!!background ? {'backgroundImage': 'url('+ background +')'} : null}>
            <header className="App-banner">
            {
                banner ? 
                <img src={banner} alt="banner" />
                :
                <div className="noimg">
                    No Image
                </div>
                
            }
            </header>

            {
                pathArray === 'leaderboard_banner' ?
                <div className="App-content">
                    <p className="App-content-rank">
                        <span className="text-normal">คุณอยู่อันดับที่</span>
                        <br/>
                        <span className="text-orange">{userRank}</span>
                    </p>
                </div>
                :
                <React.Fragment>
                    <div className="App-select-period">
                        { getSelectPeriod() }
                    </div>

                    <div className="App-content">
                        {
                            dataLeaderboard?.text4_lines?.map((item,i)=>{
                                return (
                                    <p key={i} id={`text-group-${i+1}`}>
                                        <span className="text-normal">อันดับ</span>&nbsp;
                                        <span className="text-orange">{item.order}</span>&nbsp;
                                        <span className="text-noraml">รับ</span>&nbsp;
                                        <span className="text-orange">{item.reward}</span>&nbsp;
                                    </p>
                                );
                            })
                        }
                    </div>
                    <div className="App-table">
                        <div className="App-table-border">
                            <table className="App-table-top">
                                <thead>
                                    <tr>
                                        <th>ลำดับ</th>
                                        <th>ร้านค้า</th>
                                        <th>{dataLeaderboard?.metric_content}</th>
                                        {/* <th>รางวัล</th> */}
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        {
                            isLoaded ?
                                <React.Fragment>
                                    <div className="App-table-border">
                                        <table className="App-table-self">
                                            <thead>
                                                <tr>
                                                    <th>{userRank}</th>
                                                    <th>{userName}</th>
                                                    <th>{userProgress}</th>
                                                    {/* <th>{userRewardText}</th> */}
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
            
                                    <div className="App-table-border" style={{maxHeight: (window.innerHeight/3)+'px', overflow: 'auto'}}>
                                        <table className="App-table-top">
                                            <tbody>
                                                {
                                                    getTableRow()
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            : 
                            hasError ?
                                <div className="Table-loading">
                                    <p>ไม่สามารถแสดงข้อมูลได้</p>
                                </div>
                            :
                                <div className="Table-loading">
                                    <p>...Loading...</p>
                                </div>
                        }
                    </div>

                    <div className="App-status">
                        <div className="status-content">
                            <span className="text3 _4">ข้อมูล ณ วันที่ {calcUpdatedDate(updateTime)} น.</span>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default Leader2
