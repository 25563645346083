import React, { useEffect} from 'react'
import moment from 'moment';
// import { Chart as ChartJS, ArcElement, Legend, } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';

// import approved from '../assets/images/campaign1/bullet-green.png';
// import pending from '../assets/images/campaign1/bullet-yellow.png';

// const mockup = {
//     "campaign": {
//         "id": 83,
//         "type": "weekly",
//         "name": "UAT E-Coupon ",
//         "group_id": 76,
//         "start_date": "2022-06-12 00:00:00",
//         "end_date": "2022-08-31 23:59:59",
//         "status": "ongoing",
//         "created_time": "2022-06-12 22:32:50",
//         "modified_time": "2022-06-12 22:45:49",
//         "modified_by": 3,
//         "end_processing_time": null,
//         "lock_upload": 0,
//         "lock_background": 0,
//         "background_id": 0,
//         "last_target_upload": "2022-06-13 09:29:19",
//         "last_progress_upload": null,
//         "last_team_upload": null,
//         "group": {
//             "id": 76,
//             "auto_number": "a0F4H00000cP09z",
//             "group_code": "PROGRESSBAR",
//             "reason_description": "TH: Progress bar",
//             "description": null,
//             "status": "active",
//             "created_time": "2021-06-10 11:45:57",
//             "modified_time": "2021-06-10 11:45:57",
//             "modified_by": 0
//         },
//         "weekly": {
//             "background_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/barcode/_1655269344517_.png",
//             "headline_picture": null,
//             "prize_all_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/weekly/_1655048072570_.png",
//             "text1a_content": "custom",
//             "text1a_specify": "สแกน",
//             "text1a_color": "000000",
//             "text1a_stroke_color": "ffffff",
//             "text1a_shadow_color": "000000",
//             "text1a_size": 1,
//             "text1a_stroke_width": 1,
//             "text1a_shadow_width": 2,
//             "text1b_content": "target_cur",
//             "text1b_specify": "",
//             "text1b_color": "559793",
//             "text1b_stroke_color": "000000",
//             "text1b_shadow_color": "ffffff",
//             "text1b_size": 1,
//             "text1b_stroke_width": 1,
//             "text1b_shadow_width": 2,
//             "text1c_content": "custom",
//             "text1c_specify": "ต่อสัปดาห์ รับ",
//             "text1c_color": "000000",
//             "text1c_stroke_color": "000000",
//             "text1c_shadow_color": "ffffff",
//             "text1c_size": 1,
//             "text1c_stroke_width": 1,
//             "text1c_shadow_width": 2,
//             "text1d_content": "reward_cur",
//             "text1d_specify": "",
//             "text1d_color": "559793",
//             "text1d_stroke_color": "000000",
//             "text1d_shadow_color": "ffffff",
//             "text1d_size": 1,
//             "text1d_stroke_width": 1,
//             "text1d_shadow_width": 2,
//             "text1e_content": "custom",
//             "text1e_specify": "",
//             "text1e_color": "000000",
//             "text1e_stroke_color": "000000",
//             "text1e_shadow_color": "ffffff",
//             "text1e_size": 1,
//             "text1e_stroke_width": 1,
//             "text1e_shadow_width": 2,
//             "text2a_content": "custom",
//             "text2a_specify": "สแกนติดต่อกัน",
//             "text2a_color": "000000",
//             "text2a_stroke_color": "ffffff",
//             "text2a_shadow_color": "000000",
//             "text2a_size": 1,
//             "text2a_stroke_width": 1,
//             "text2a_shadow_width": 2,
//             "text2b_content": "no_period",
//             "text2b_specify": "",
//             "text2b_color": "559793",
//             "text2b_stroke_color": "000000",
//             "text2b_shadow_color": "ffffff",
//             "text2b_size": 1,
//             "text2b_stroke_width": 1,
//             "text2b_shadow_width": 2,
//             "text2c_content": "custom",
//             "text2c_specify": "สัปดาห์ รับ",
//             "text2c_color": "000000",
//             "text2c_stroke_color": "000000",
//             "text2c_shadow_color": "ffffff",
//             "text2c_size": 1,
//             "text2c_stroke_width": 1,
//             "text2c_shadow_width": 2,
//             "text2d_content": "reward_all",
//             "text2d_specify": "",
//             "text2d_color": "559793",
//             "text2d_stroke_color": "000000",
//             "text2d_shadow_color": "ffffff",
//             "text2d_size": 1,
//             "text2d_stroke_width": 1,
//             "text2d_shadow_width": 2,
//             "text2e_content": "custom",
//             "text2e_specify": "",
//             "text2e_color": "000000",
//             "text2e_stroke_color": "000000",
//             "text2e_shadow_color": "ffffff",
//             "text2e_size": 1,
//             "text2e_stroke_width": 1,
//             "text2e_shadow_width": 2,
//             "text3_color": "000000",
//             "text3_stroke_color": "000000",
//             "text3_shadow_color": "ffffff",
//             "text3_size": 1,
//             "text3_stroke_width": 1,
//             "text3_shadow_width": 2,
//             "text4_color": "000000",
//             "text4_stroke_color": "000000",
//             "text4_shadow_color": "ffffff",
//             "text4_size": 1,
//             "text4_stroke_width": 1,
//             "text4_shadow_width": 2,
//             "text5_color": "000000",
//             "text5_stroke_color": "000000",
//             "text5_shadow_color": "ffffff",
//             "text5_size": 1,
//             "text5_stroke_width": 1,
//             "text5_shadow_width": 2,
//             "chart_unreached_color": "000000",
//             "chart_reached_color": "000000",
//             "progress_upload_manual": 1,
//             "product_data_source": "gt",
//             "product_code_type": "non_ryo",
//             "period": [
//                 {
//                     "start_date": "2022-06-12",
//                     "end_date": "2022-06-18",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/weekly/_1655048241743_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [],
//                         "gt": []
//                     },
//                     "target": 15,
//                     "reward_type": "ecoupon",
//                     "auto_grant": 1,
//                     "sfdc_point": 50,
//                     "reward_name": "บัตรกำนัล 100 บาท",
//                     "face_value": 11,
//                     "topup": 1
//                 },
//                 {
//                     "start_date": "2022-06-19",
//                     "end_date": "2022-06-25",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/weekly/_1655048241743_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [],
//                         "gt": []
//                     },
//                     "target": 10,
//                     "reward_type": "ecoupon",
//                     "auto_grant": 1,
//                     "sfdc_point": 100,
//                     "reward_name": "บัตรกำนัล 222 บาท",
//                     "face_value": 22,
//                     "topup": 2
//                 },
//                 {
//                     "start_date": "2022-06-26",
//                     "end_date": "2022-07-02",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/weekly/_1655048241743_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [],
//                         "gt": []
//                     },
//                     "target": 10,
//                     "reward_type": "ecoupon",
//                     "auto_grant": 1,
//                     "sfdc_point": 100,
//                     "reward_name": "บัตรกำนัล 333 บาท",
//                     "face_value": 33,
//                     "topup": 3
//                 },
//                 {
//                     "start_date": "2022-07-03",
//                     "end_date": "2022-07-09",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/weekly/_1655048241743_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [],
//                         "gt": []
//                     },
//                     "target": 10,
//                     "reward_type": "ecoupon",
//                     "auto_grant": 1,
//                     "sfdc_point": 100,
//                     "reward_name": "บัตรกำนัล 444 บาท",
//                     "face_value": 44,
//                     "topup": 4
//                 },
//                 {
//                     "start_date": "2022-07-10",
//                     "end_date": "2022-07-16",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/weekly/_1655048241743_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [],
//                         "gt": []
//                     },
//                     "target": 10,
//                     "reward_type": "ecoupon",
//                     "auto_grant": 1,
//                     "sfdc_point": 100,
//                     "reward_name": "บัตรกำนัล 444 บาท",
//                     "face_value": 44,
//                     "topup": 4
//                 }
//             ],
//             "reward_all": {
//                 "reward_type": "sfdc",
//                 "auto_grant": 1,
//                 "sfdc_point": 1234,
//                 "reward_name": "บัตรกำนัลโลตัส 550 บาท",
//                 "face_value": 66,
//                 "topup": 6
//             },
//             "target_unit": "แถว",
//             "text1a_output": "สแกน",
//             "text1b_output": "10 แถว",
//             "text1c_output": "ต่อสัปดาห์ รับ",
//             "text1d_output": "คูปองส่วนลดมูลค่า 33 บาท",
//             "text1e_output": "",
//             "text2a_output": "สแกนติดต่อกัน",
//             "text2b_output": 4,
//             "text2c_output": "สัปดาห์ รับ",
//             "text2d_output": "1234 คะแนน",
//             "text2e_output": "",
//             "text_no_period": 4,
//             "text_reward_all": "1234 คะแนน",
//             "text_reward_cur": "คูปองส่วนลดมูลค่า 33 บาท",
//             "text_target_cur": "10 แถว"
//         }
//     },
//     "user": {
//         "progress": [
//             16,
//             10,
//             0,
//             0,
//             0
//         ],
//         "updated_time": "2022-06-13 15:29:15"
//     },
//     "version": 2
// }

// ChartJS.register(ArcElement, Legend);

function Weekly2(props) {
    // const [isLoaded, setIsLoaded] = useState(false)
    // const [targetPos, setTargetPos] = useState([]);
    // const [appBar, setAppBar] = useState(0)

    const { dataCampaign } = props;
    // const dataCampaign = mockup
    const dataWeekly = dataCampaign.campaign.weekly
    const dataUser = dataCampaign.user;

    // Target data
    const campaignPeriod = dataWeekly.period;
    const campaignTargetUnit = dataWeekly.target_unit // "ลัง";  ;
    const userProgress = dataUser.progress // 16 ;

    // Images data
    const banner = dataWeekly.background_picture;
    const updateTime = dataUser.updated_time;
    // const startDate = dataCampaign.campaign.start_date;
    // const endDate = dataCampaign.campaign.end_date;
    // const bar = dataWeekly.progressbar_picture;
    
    useEffect(() => {
        // console.log(barRef)
        // console.log(barRef.current.clientWidth)
        // setAppBar(barRef.current.clientWidth)
        
        // console.log(dataCampaign)

    
    }, [campaignTargetUnit, dataUser.checked, dataUser.pending, userProgress])

    const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']
    const calcDate = (valStart, valEnd) => {
        const startDay = moment(valStart).format('DD')
        const endDay = moment(valEnd).format('DD')
        const startMonth = Number(moment(valStart).format('MM'))
        const endMonth = Number(moment(valEnd).format('MM'))
// console.log(startMonth, endMonth)

        if(startMonth === endMonth) {
            return (startDay+' - '+endDay+' '+monthArr[endMonth -1])
        } else {
            return (startDay+' '+monthArr[startMonth-1]+' - '+endDay+' '+monthArr[endMonth-1])
        }

    }

    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' '+dataTime)
    }

    const calcProgressValue = (progressVal, ind) => {
        let int_part = Math.trunc(progressVal); // returns 3
        let float_part = Number((progressVal - int_part).toFixed(2)); 
        // console.log(int_part)
        // console.log(float_part)
        // console.log(dataWeekly.product_code_type)

        let boxValue = int_part;
        let rowValue = 0;

        if(dataWeekly.product_code_type === "non_ryo") {
            rowValue = float_part * 50
        } else if(dataWeekly.product_code_type === "ryo") {
            rowValue = float_part * 20
        } else {
            rowValue = float_part
        }

        return (
            <React.Fragment>
                <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text3reached _1':'text3 _1'}>{ boxValue }</span>&nbsp;
                <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text4reached _5':'text4 _5'}>ลัง</span>&nbsp;
                {
                    rowValue > 0 ?
                        <React.Fragment>
                            <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text3reached _2':'text3 _2'}>{ rowValue }</span>&nbsp;
                            <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text4reached _6':'text4 _6'}>แถว</span>
                        </React.Fragment>
                    : null
                }
            </React.Fragment>
        )

        // if(dataWeekly.product_code_type === "non_ryo") {
        //     setCampaignProgressValueRow(float_part * 50)
        // } else if(dataWeekly.product_code_type === "ryo") {
        //     setCampaignProgressValueRow(float_part * 20)
        // } else {
        //     setCampaignProgressValueRow(float_part)
        // }

        // setCampaignProgressValueBox(int_part)
        
        // return Number.isInteger(campaignTargetUnit) ? campaignTargetValue : campaignTargetValue;
    }


    // Chart.js - fn Doughnut chart
    const dottedChart = (userProgressVal, campaignPeriodTargetVal, imgChartVal, startDateVal, endDateVal, ind) => {

        let checkMark = false;

        if(userProgressVal >= campaignPeriodTargetVal) {
            checkMark = true
        } else {
            checkMark = false;
        }

        const calcWidthDotted = () => {
            let result = (window.innerWidth/4);
            // console.log(result)
            return result
        }

        const calcWidthImage = () => {
            let result = (window.innerWidth/4)-45
            
            return result
        }

        const calcPeriodDays = () => {
            // console.log('daysAgo',moment().subtract(3,'d').format('YYYY-MM-DD')) // for test period day
            let currentDate = moment([moment().format('YYYY'), moment().format('MM'), moment().format('DD')], 'YYYY-MM-DD') // moment([moment().subtract(3,'d').format('YYYY-MM-DD')], 'YYYY-MM-DD') //
            let startDate = moment([moment(startDateVal).format('YYYY'), moment(startDateVal).format('MM'), moment(startDateVal).format('DD')], 'YYYY-MM-DD')
            let result = Number(startDate.diff(currentDate, 'days')) <= 0 ? true : false
            // console.log('diff date', moment(currentDate).format('DD-MM-YYYY'),' && ',moment(startDate).format('DD-MM-YYYY'), ' = ',result)
            return result
        }

        const calcNowOnPeriod = () => {
            let currentDate = moment()
            let result = currentDate.isBetween(startDateVal, endDateVal, 'days', true)
            // console.log(currentDate.format('YYYY-MM-DD'), startDateVal, endDateVal, result)
            return result
        }
        

        return (
            <div className={calcPeriodDays() ? `App-dotted _reached _period${campaignPeriod.length}`: `App-dotted _unreached _period${campaignPeriod.length}`} style={{width: calcWidthDotted() - 10 +'px', height: calcWidthDotted() - 40 + 'px'}}>
                {/* <style dangerouslySetInnerHTML={{
                    __html: [
                        '#bartext_'+(ind+1)+' .App-checkMark:after { content: "✓"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; right: 0; bottom: 0; z-index: -1; display: flex; align-items: center; justify-content: center;}',
                        ].join('\n')
                }}></style> */}
                {/* <div className="App-imgChart" style={{width: calcWidthImage()+'px', height: calcWidthImage()+'px'}}>
                    <img src={imgChart} alt="imagechart"  />
                    { checkMark ? <div className="App-checkMark" style={{width: calcWidthImage()+'px', height: calcWidthImage()+'px', color: reachedColor}}>✓</div> : null }
                </div> */}
                {/* <Doughnut data={data} options={options} /> */}
                <div className={calcNowOnPeriod() ? "dottedItem _current" : "dottedItem"} style={checkMark ? {backgroundColor: '#199F98'} : {backgroundColor: '#D6D8E3'}}>
                    { checkMark ? <div className="App-checkMark" style={{width: calcWidthImage()-20+'px', height: calcWidthImage()-20+'px', color: '#fff'}}><div className="checkmark"></div></div> : null }
                </div>
            </div>
        )

    }



    return (
        <div className="App-weekly-V2">
            <header className="App-banner">
            {
                    banner ? 
                    <img src={banner} alt="banner" />
                    :
                    <div className="noimg">
                        No Image
                    </div>
                    
                }
            </header>
            <div className="App-content">
                <React.Fragment>
                    <p>
                        {
                            dataWeekly.text1a_content === 'custom' ? <span className='text1a custom'>{ dataWeekly.text1a_output }</span> : <span className='text1a'>{ dataWeekly.text1a_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text1b_content === 'custom' ? <span className='text1b custom'>{ dataWeekly.text1b_output }</span> : <span className='text1b'>{ dataWeekly.text1b_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text1c_content === 'custom' ? <span className='text1c custom'>{ dataWeekly.text1c_output }</span> : <span className='text1c'>{ dataWeekly.text1c_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text1d_content === 'custom' ? <span className='text1d custom'>{ dataWeekly.text1d_output }</span> : <span className='text1d'>{ dataWeekly.text1d_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text1e_content === 'custom' ? <span className='text1e custom'>{ dataWeekly.text1e_output }</span> : <span className='text1e'>{ dataWeekly.text1e_output }</span>
                        }
                        &nbsp;
                        <br/>
                        {
                            dataWeekly.text2a_content === 'custom' ? <span className='text2a custom'>{ dataWeekly.text2a_output }</span> : <span className='text2a'>{ dataWeekly.text2a_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text2b_content === 'custom' ? <span className='text2b custom'>{ dataWeekly.text2b_output }</span> : <span className='text2b'>{ dataWeekly.text2b_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text2c_content === 'custom' ? <span className='text2c custom'>{ dataWeekly.text2c_output }</span> : <span className='text2c'>{ dataWeekly.text2c_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text2d_content === 'custom' ? <span className='text2d custom'>{ dataWeekly.text2d_output }</span> : <span className='text2d'>{ dataWeekly.text2d_output }</span>
                        }
                        &nbsp;
                        {
                            dataWeekly.text2e_content === 'custom' ? <span className='text2e custom'>{ dataWeekly.text2e_output }</span> : <span className='text2e'>{ dataWeekly.text2e_output }</span>
                        }
                        &nbsp;
                    </p>
                    {/* <p>
                        สแกน <span>{dataWeekly.text_target_cur}</span> ต่อสัปดาห์ รับส่วนลด <span>{dataWeekly.text_reward_all}</span>
                    </p>
                    <p>
                        สแกนติดต่อกัน <span>{dataWeekly.text_no_period}</span> สัปดาห์ รับ <span>{dataWeekly.text_reward_cur}</span>
                    </p> */}
                </React.Fragment>
            </div>
            
            <div className="App-bar-result" id="App-bar-result">
                <div className="App-bar-text">
                    {
                        campaignPeriod.map((item,i)=>{
                            return (
                                <React.Fragment key={i}>
                                    <div id={`bartext_${i+1}`} className={`App-bar-text-item _${i+1}`}>
                                        

                                        
                                        {/* call fn Doughnut chart */}
                                        { 
                                            dottedChart(userProgress[i], campaignPeriod[i].target, campaignPeriod[i].period_picture, campaignPeriod[i].start_date, campaignPeriod[i].end_date, i) 
                                        }


                                        <div className="App-bar-text-box" style={{height: (window.innerWidth/4)-40+'px'}}>
                                            <span className="text5 _1">{calcDate(item.start_date, item.end_date)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _4':'text4 _4'}>สแกนแล้ว</span>&nbsp;

                                            {
                                                dataWeekly.target_unit === 'ลัง' ?
                                                calcProgressValue(userProgress[i], i)
                                                :
                                                <React.Fragment>
                                                    <span className={userProgress[i] >= campaignPeriod[i].target? 'text3reached':'text3'}>{userProgress[i]}</span>&nbsp;
                                                    {/* <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _1':'text4 _1'}> / </span>&nbsp;
                                                    <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _2':'text4 _2'}>{campaignPeriod[i].target}</span>&nbsp; */}
                                                    <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _3':'text4 _3'}>{campaignTargetUnit}</span>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
           
            <div className="App-status">
                <div className="status-content">
                    <span className="text3 _4">ข้อมูล ณ วันที่ {calcUpdatedDate(updateTime)} น.</span>
                </div>
            </div>
        </div>
    )

}

export default Weekly2
