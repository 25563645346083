import React, { useEffect, useState, useRef, useCallback } from 'react'
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Legend, } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// import approved from '../assets/images/campaign1/bullet-green.png';
// import pending from '../assets/images/campaign1/bullet-yellow.png';

// const mockup = {
//     "campaign": {
//         "id": 57,
//         "type": "weekly",
//         "name": "test create weekly",
//         "group_id": 76,
//         "start_date": "2022-02-08 00:00:00",
//         "end_date": "2022-03-07 23:59:59",
//         "status": "ongoing",
//         "created_time": "2022-02-02 21:50:44",
//         "modified_time": "2022-02-02 21:50:44",
//         "modified_by": 1,
//         "end_processing_time": null,
//         "lock_upload": 0,
//         "lock_background": 0,
//         "background_id": 0,
//         "last_target_upload": null,
//         "last_progress_upload": null,
//         "last_team_upload": null,
//         "group": {
//             "id": 76,
//             "auto_number": "a0F4H00000cP09z",
//             "group_code": "PROGRESSBAR",
//             "reason_description": "TH: Progress bar",
//             "description": null,
//             "status": "active",
//             "created_time": "2021-06-10 11:45:57",
//             "modified_time": "2021-06-10 11:45:57",
//             "modified_by": 0
//         },
//         "weekly": {
//             "background_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327024553_.png",
//             "headline_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327037211_.png",
//             "prize_all_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327238186_.png",
//             "text1a_content": "custom",
//             "text1a_specify": "สแกน",
//             "text1a_color": "000000",
//             "text1a_stroke_color": "FFFFFF",
//             "text1a_shadow_color": "000000",
//             "text1a_size": 1.5,
//             "text1a_stroke_width": 4,
//             "text1a_shadow_width": 1,
//             "text1b_content": "target_cur",
//             "text1b_specify": "",
//             "text1b_color": "0000FF",
//             "text1b_stroke_color": "FFFFFF",
//             "text1b_shadow_color": "000000",
//             "text1b_size": 1.5,
//             "text1b_stroke_width": 4,
//             "text1b_shadow_width": 2,
//             "text1c_content": "custom",
//             "text1c_specify": "ต่อสัปดาห์  รับ",
//             "text1c_color": "000000",
//             "text1c_stroke_color": "FFFFFF",
//             "text1c_shadow_color": "000000",
//             "text1c_size": 1.5,
//             "text1c_stroke_width": 4,
//             "text1c_shadow_width": 2,
//             "text1d_content": "reward_cur",
//             "text1d_specify": "",
//             "text1d_color": "0000FF",
//             "text1d_stroke_color": "FFFFFF",
//             "text1d_shadow_color": "000000",
//             "text1d_size": 1.5,
//             "text1d_stroke_width": 4,
//             "text1d_shadow_width": 2,
//             "text1e_content": "custom",
//             "text1e_specify": "",
//             "text1e_color": "000000",
//             "text1e_stroke_color": "000000",
//             "text1e_shadow_color": "ffffff",
//             "text1e_size": 1,
//             "text1e_stroke_width": 1,
//             "text1e_shadow_width": 2,
//             "text2a_content": "custom",
//             "text2a_specify": "สแกนติดต่อกัน",
//             "text2a_color": "000000",
//             "text2a_stroke_color": "FFFFFF",
//             "text2a_shadow_color": "000000",
//             "text2a_size": 1.5,
//             "text2a_stroke_width": 4,
//             "text2a_shadow_width": 2,
//             "text2b_content": "no_period",
//             "text2b_specify": "",
//             "text2b_color": "0000FF",
//             "text2b_stroke_color": "FFFFFF",
//             "text2b_shadow_color": "000000",
//             "text2b_size": 1.5,
//             "text2b_stroke_width": 4,
//             "text2b_shadow_width": 2,
//             "text2c_content": "custom",
//             "text2c_specify": "สัปดาห์ รับ",
//             "text2c_color": "000000",
//             "text2c_stroke_color": "FFFFFF",
//             "text2c_shadow_color": "000000",
//             "text2c_size": 1.5,
//             "text2c_stroke_width": 4,
//             "text2c_shadow_width": 2,
//             "text2d_content": "reward_all",
//             "text2d_specify": "",
//             "text2d_color": "0000FF",
//             "text2d_stroke_color": "FFFFFF",
//             "text2d_shadow_color": "000000",
//             "text2d_size": 1.5,
//             "text2d_stroke_width": 4,
//             "text2d_shadow_width": 2,
//             "text2e_content": "custom",
//             "text2e_specify": "",
//             "text2e_color": "000000",
//             "text2e_stroke_color": "000000",
//             "text2e_shadow_color": "ffffff",
//             "text2e_size": 1,
//             "text2e_stroke_width": 1,
//             "text2e_shadow_width": 2,
//             "text3_color": "0000FF",
//             "text3_stroke_color": "FFFFFF",
//             "text3_shadow_color": "000000",
//             "text3_size": 1.2,
//             "text3_stroke_width": 4,
//             "text3_shadow_width": 2,
//             "text4_color": "000000",
//             "text4_stroke_color": "FFFFFF",
//             "text4_shadow_color": "000000",
//             "text4_size": 1.2,
//             "text4_stroke_width": 4,
//             "text4_shadow_width": 2,
//             "text5_color": "000000",
//             "text5_stroke_color": "FFFFFF",
//             "text5_shadow_color": "000000",
//             "text5_size": 1,
//             "text5_stroke_width": 3,
//             "text5_shadow_width": 1,
//             "chart_unreached_color": "7F7F7F",
//             "chart_reached_color": "00FF00",
//             "progress_upload_manual": 0,
//             "product_data_source": "ws",
//             "product_code_type": "non_ryo",
//             "period": [
//                 {
//                     "start_date": "2022-02-08",
//                     "end_date": "2022-02-14",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327232790_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [
//                             "LM71MFTB"
//                         ],
//                         "gt": []
//                     },
//                     "target": 15,
//                     "reward_type": "sfdc",
//                     "auto_grant": 1,
//                     "sfdc_point": 50,
//                     "reward_name": null
//                 },
//                 {
//                     "start_date": "2022-02-15",
//                     "end_date": "2022-02-16",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327232790_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [
//                             "LM71MFTB"
//                         ],
//                         "gt": []
//                     },
//                     "target": 15.5,
//                     "reward_type": "sfdc",
//                     "auto_grant": 1,
//                     "sfdc_point": 60,
//                     "reward_name": null
//                 },
//                 {
//                     "start_date": "2022-02-17",
//                     "end_date": "2022-02-28",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327232790_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [
//                             "LM71MFTB"
//                         ],
//                         "gt": []
//                     },
//                     "target": 5,
//                     "reward_type": "sfdc",
//                     "auto_grant": 1,
//                     "sfdc_point": 70,
//                     "reward_name": null
//                 },
//                 {
//                     "start_date": "2022-03-01",
//                     "end_date": "2022-04-09",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327232790_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [
//                             "LM71MFTB"
//                         ],
//                         "gt": []
//                     },
//                     "target": 5,
//                     "reward_type": "sfdc",
//                     "auto_grant": 1,
//                     "sfdc_point": 80,
//                     "reward_name": null
//                 },
//                 {
//                     "start_date": "2022-03-01",
//                     "end_date": "2022-04-09",
//                     "period_picture": "https://s3.ap-southeast-1.amazonaws.com/sandbox.pmi-gameworld-upload/progressbar/team/_1644327232790_.png",
//                     "sku": {
//                         "ryo": [],
//                         "non_ryo": [
//                             "LM71MFTB"
//                         ],
//                         "gt": []
//                     },
//                     "target": 5,
//                     "reward_type": "sfdc",
//                     "auto_grant": 1,
//                     "sfdc_point": 80,
//                     "reward_name": null
//                 }
//             ],
//             "reward_all": {
//                 "reward_type": "other",
//                 "auto_grant": 0,
//                 "sfdc_point": 0,
//                 "reward_name": "บัตรกำนัล"
//             },
//             // "target_unit": "แถว",
//             "target_unit": "ลัง",
//             "text1a_output": "สแกน",
//             "text1b_output": "10 แถว",
//             "text1c_output": "ต่อสัปดาห์  รับ",
//             "text1d_output": "50 คะแนน",
//             "text1e_output": "",
//             "text2a_output": "สแกนติดต่อกัน",
//             "text2b_output": 4,
//             "text2c_output": "สัปดาห์ รับ",
//             "text2d_output": "บัตรกำนัล",
//             "text2e_output": ""
//         }
//     },
//     "user": {
//         "progress": [
//             15,
//             15.5,
//             4.10,
//             1.6,
//             1
//         ]
//     }
// }

ChartJS.register(ArcElement, Legend);

function Weekly(props) {
    const barRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    // const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    // const [appBar, setAppBar] = useState(0)

    const { dataCampaign } = props;
    // const dataCampaign = mockup
    const dataWeekly = dataCampaign.campaign.weekly
    const dataUser = dataCampaign.user;

    // Target data
    const campaignPeriod = dataWeekly.period;
    const campaignTargetUnit = dataWeekly.target_unit // "ลัง";  ;
    const userProgress = dataUser.progress // 16 ;

    // Images data
    const background = dataWeekly.background_picture;
    const header = dataWeekly.headline_picture;
    // const bar = dataWeekly.progressbar_picture;

    const calcBarPos = useCallback(() => {
        const pb_length = barRef.current.clientWidth;
        const num_target = campaignPeriod.length;

        let paint_length = 0;
        if( userProgress > num_target) {
            // Paint full bar : green
            setBarGreen(pb_length);
        } else { 
            // Paint bar : green
            paint_length = (num_target - userProgress) * (pb_length / num_target);
            setBarGreen(pb_length - paint_length);
            
        } 
        
    },[campaignPeriod, userProgress])
    
    useEffect(() => {
        // console.log(barRef)
        // console.log(barRef.current.clientWidth)
        // setAppBar(barRef.current.clientWidth)
        
        // console.log(dataCampaign)

        calcBarPos();
    }, [barGreen, calcBarPos, campaignTargetUnit, dataUser.checked, dataUser.pending, userProgress])

    const calcDate = (valStart, valEnd) => {
        const startDay = moment(valStart).format('DD')
        const endDay = moment(valEnd).format('DD')
        const startMonth = Number(moment(valStart).format('MM'))
        const endMonth = Number(moment(valEnd).format('MM'))
// console.log(startMonth, endMonth)
        const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']

        if(startMonth === endMonth) {
            return (startDay+' - '+endDay+' '+monthArr[endMonth -1])
        } else {
            return (startDay+' '+monthArr[startMonth-1]+' - '+endDay+' '+monthArr[endMonth-1])
        }

    }

    const calcProgressValue = (progressVal, ind) => {
        let int_part = Math.trunc(progressVal); // returns 3
        let float_part = Number((progressVal - int_part).toFixed(2)); 
        // console.log(int_part)
        // console.log(float_part)
        // console.log(dataWeekly.product_code_type)

        let boxValue = int_part;
        let rowValue = 0;

        if(dataWeekly.product_code_type === "non_ryo") {
            rowValue = float_part * 50
        } else if(dataWeekly.product_code_type === "ryo") {
            rowValue = float_part * 20
        } else {
            rowValue = float_part
        }

        return (
            <React.Fragment>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '#bartext_'+(ind+1)+' .text3reached._1:before { content: "'+(boxValue)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                        '#bartext_'+(ind+1)+' .text3._1:before { content: "'+(boxValue)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                        '#bartext_'+(ind+1)+' .text3reached._2:before { content: "'+(rowValue)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                        '#bartext_'+(ind+1)+' .text3._2:before { content: "'+(rowValue)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                       
                        '#bartext_'+(ind+1)+' .text4reached._5:before { content: "ลัง"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                        '#bartext_'+(ind+1)+' .text4._5:before { content: "ลัง"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                        '#bartext_'+(ind+1)+' .text4reached._6:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                        '#bartext_'+(ind+1)+' .text4._6:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
              
                    ].join('\n')
                }}></style>
                <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text3reached _1':'text3 _1'}>{ boxValue }</span>&nbsp;
                <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text4reached _5':'text4 _5'}>ลัง</span>&nbsp;
                {
                    rowValue > 0 ?
                        <React.Fragment>
                            <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text3reached _2':'text3 _2'}>{ rowValue }</span>&nbsp;
                            <span className={userProgress[ind] >= campaignPeriod[ind].target? 'text4reached _6':'text4 _6'}>แถว</span>
                        </React.Fragment>
                    : null
                }
            </React.Fragment>
        )

        // if(dataWeekly.product_code_type === "non_ryo") {
        //     setCampaignProgressValueRow(float_part * 50)
        // } else if(dataWeekly.product_code_type === "ryo") {
        //     setCampaignProgressValueRow(float_part * 20)
        // } else {
        //     setCampaignProgressValueRow(float_part)
        // }

        // setCampaignProgressValueBox(int_part)
        
        // return Number.isInteger(campaignTargetUnit) ? campaignTargetValue : campaignTargetValue;
    }


    // Chart.js - fn Doughnut chart
    const doughnutChart = (userProgressVal, campaignPeriodTargetVal, imgChartVal, startDateVal, ind) => {
        const reachedColor = '#'+dataWeekly.chart_reached_color
        const unreachedColor = '#'+dataWeekly.chart_unreached_color

        let checkMark = false;
        let reachedVal = 0
        let unreachedVal = 0

        if(userProgressVal >= campaignPeriodTargetVal) {
            reachedVal = userProgressVal
            checkMark = true
        } else {
            reachedVal = userProgressVal
            unreachedVal = campaignPeriodTargetVal - userProgressVal
            checkMark = false;
        }

        const imgChart = imgChartVal

        const data = {
            datasets: [
                {
                data: [reachedVal, unreachedVal],
                backgroundColor: [
                    reachedColor,
                    unreachedColor,
                ],
                borderWidth: 0,
                },
            ],
        };

        const options = {
            animation: {
                duration: 0
            }
        }

        const calcWidthDoughnut = () => {
            // let result = (window.innerWidth/campaignPeriod.length)-(campaignPeriod.length*(campaignPeriod.length===5?4:5));
            let radius = campaignPeriod.length === 1? 250 : campaignPeriod.length === 2 ? 100 : campaignPeriod.length === 3 ? 50 : 20
            let result = (window.innerWidth/campaignPeriod.length)-radius;
            
            return result
        }

        const calcWidthImage = () => {
            let radius = campaignPeriod.length === 1? 280 : campaignPeriod.length === 2 ? 130 : campaignPeriod.length === 3 ? 80 : 45
            let result = (window.innerWidth/campaignPeriod.length)-radius
            
            return result
        }

        const calcPeriodDays = () => {
            let currentDate = moment([moment().format('YYYY'), moment().format('MM'), moment().format('DD')])
            let startDate = moment([moment(startDateVal).format('YYYY'), moment(startDateVal).format('MM'), moment(startDateVal).format('DD')])
            let result = Number(startDate.diff(currentDate, 'days')) <= 0 ? true : false
            // console.log('diff date', moment(currentDate).format('DD-MM-YYYY'),' && ',moment(startDate).format('DD-MM-YYYY'), ' = ',result)

            return result
        }


        return (
            <div className={calcPeriodDays() ? `App-doughnutChart _reached _period${campaignPeriod.length}`: `App-doughnutChart _unreached _period${campaignPeriod.length}`} style={{width: calcWidthDoughnut() +'px', height: calcWidthDoughnut() + 'px'}}>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '#bartext_'+(ind+1)+' .App-checkMark:after { content: "✓"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; right: 0; bottom: 0; z-index: -1; display: flex; align-items: center; justify-content: center;}',
                        ].join('\n')
                }}></style>
                <div className="App-imgChart" style={{width: calcWidthImage()+'px', height: calcWidthImage()+'px'}}>
                    <img src={imgChart} alt="imagechart"  />
                    { checkMark ? <div className="App-checkMark" style={{width: calcWidthImage()+'px', height: calcWidthImage()+'px', color: reachedColor}}>✓</div> : null }
                </div>
                <Doughnut data={data} options={options} />
            </div>
        )

    }



    return (
        <div className="App-weekly">
            <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                <header className="App-header">
                    <img src={header} className="App-logo" alt="logo" />
                </header>
                <div className="App-content">
                    <React.Fragment>
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text1a{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text1a_size+'rem;',
                                    'color: #'+dataWeekly.text1a_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text1a:before {',
                                    'text-shadow: '+dataWeekly.text1a_shadow_width+'px '+dataWeekly.text1a_shadow_width+'px '+dataWeekly.text1a_shadow_width+'px #'+dataWeekly.text1a_shadow_color+';',
                                '}',
                                '.text1b{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text1b_size+'rem;',
                                    'color: #'+dataWeekly.text1b_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text1b:before {',
                                    'text-shadow: '+dataWeekly.text1b_shadow_width+'px '+dataWeekly.text1b_shadow_width+'px '+dataWeekly.text1b_shadow_width+'px #'+dataWeekly.text1b_shadow_color+';',
                                '}',
                                '.text1c{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text1c_size+'rem;',
                                    'color: #'+dataWeekly.text1c_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text1c:before {',
                                    'text-shadow: '+dataWeekly.text1c_shadow_width+'px '+dataWeekly.text1c_shadow_width+'px '+dataWeekly.text1c_shadow_width+'px #'+dataWeekly.text1c_shadow_color+';',
                                '}',
                                '.text1d{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text1d_size+'rem;',
                                    'color: #'+dataWeekly.text1d_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text1d:before {',
                                    'text-shadow: '+dataWeekly.text1d_shadow_width+'px '+dataWeekly.text1d_shadow_width+'px '+dataWeekly.text1d_shadow_width+'px #'+dataWeekly.text1d_shadow_color+';',
                                '}',
                                '.text1e{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text1e_size+'rem;',
                                    'color: #'+dataWeekly.text1e_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text1e:before {',
                                    'text-shadow: '+dataWeekly.text1e_shadow_width+'px '+dataWeekly.text1e_shadow_width+'px '+dataWeekly.text1e_shadow_width+'px #'+dataWeekly.text1e_shadow_color+';',
                                '}',

                                '.text2a{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text2a_size+'rem;',
                                    'color: #'+dataWeekly.text2a_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2a:before {',
                                    'text-shadow: '+dataWeekly.text2a_shadow_width+'px '+dataWeekly.text2a_shadow_width+'px '+dataWeekly.text2a_shadow_width+'px #'+dataWeekly.text2a_shadow_color+';',
                                '}',
                                '.text2b{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text2b_size+'rem;',
                                    'color: #'+dataWeekly.text2b_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2b:before {',
                                    'text-shadow: '+dataWeekly.text2b_shadow_width+'px '+dataWeekly.text2b_shadow_width+'px '+dataWeekly.text2b_shadow_width+'px #'+dataWeekly.text2b_shadow_color+';',
                                '}',
                                '.text2c{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text2c_size+'rem;',
                                    'color: #'+dataWeekly.text2c_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2c:before {',
                                    'text-shadow: '+dataWeekly.text2c_shadow_width+'px '+dataWeekly.text2c_shadow_width+'px '+dataWeekly.text2c_shadow_width+'px #'+dataWeekly.text2c_shadow_color+';',
                                '}',
                                '.text2d{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text2d_size+'rem;',
                                    'color: #'+dataWeekly.text2d_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2d:before {',
                                    'text-shadow: '+dataWeekly.text2d_shadow_width+'px '+dataWeekly.text2d_shadow_width+'px '+dataWeekly.text2d_shadow_width+'px #'+dataWeekly.text2d_shadow_color+';',
                                '}',
                                '.text2e{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataWeekly.text2e_size+'rem;',
                                    'color: #'+dataWeekly.text2e_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2e:before {',
                                    'text-shadow: '+dataWeekly.text2e_shadow_width+'px '+dataWeekly.text2e_shadow_width+'px '+dataWeekly.text2e_shadow_width+'px #'+dataWeekly.text2e_shadow_color+';',
                                '}',
                                ].join('\n')
                                }}>
                        </style>
                        <p >
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text1a:before { content: "'+(dataWeekly.text1a_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text1a_stroke_width+'px #'+dataWeekly.text1a_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text1b:before { content: "'+(dataWeekly.text1b_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text1b_stroke_width+'px #'+dataWeekly.text1b_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text1c:before { content: "'+(dataWeekly.text1c_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text1c_stroke_width+'px #'+dataWeekly.text1c_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text1d:before { content: "'+(dataWeekly.text1d_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text1d_stroke_width+'px #'+dataWeekly.text1d_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text1e:before { content: "'+(dataWeekly.text1e_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text1e_stroke_width+'px #'+dataWeekly.text1e_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',

                                    '.text2a:before { content: "'+(dataWeekly.text2a_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text2a_stroke_width+'px #'+dataWeekly.text2a_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text2b:before { content: "'+(dataWeekly.text2b_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text2b_stroke_width+'px #'+dataWeekly.text2b_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text2c:before { content: "'+(dataWeekly.text2c_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text2c_stroke_width+'px #'+dataWeekly.text2c_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text2d:before { content: "'+(dataWeekly.text2d_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text2d_stroke_width+'px #'+dataWeekly.text2d_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text2e:before { content: "'+(dataWeekly.text2e_output)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text2e_stroke_width+'px #'+dataWeekly.text2e_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
 
                                ].join('\n')
                            }}>
                            </style>
                            <span className='text1a'>{ dataWeekly.text1a_output }</span>&nbsp;
                            <span className='text1b'>{ dataWeekly.text1b_output }</span>&nbsp;
                            <span className='text1c'>{ dataWeekly.text1c_output }</span>&nbsp;
                            <span className='text1d'>{ dataWeekly.text1d_output }</span>&nbsp;
                            <span className='text1e'>{ dataWeekly.text1e_output }</span>&nbsp;
                            <br/>
                            <span className='text2a'>{ dataWeekly.text2a_output }</span>&nbsp;
                            <span className='text2b'>{ dataWeekly.text2b_output }</span>&nbsp;
                            <span className='text2c'>{ dataWeekly.text2c_output }</span>&nbsp;
                            <span className='text2d'>{ dataWeekly.text2d_output }</span>&nbsp;
                            <span className='text2e'>{ dataWeekly.text2e_output }</span>&nbsp;
                            
                            
                        </p>
                    </React.Fragment>
                </div>
                
                <div className="App-bar-result" id="App-bar-result">
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            '.text3reached{', 
                                'font-size: '+dataWeekly.text3_size+'rem;',
                                'color: #'+dataWeekly.chart_reached_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text3reached:before {',
                                'text-shadow: '+dataWeekly.text3_shadow_width+'px '+dataWeekly.text3_shadow_width+'px '+dataWeekly.text3_shadow_width+'px #'+dataWeekly.text3_shadow_color+';',
                            '}',
                            '.text3{', 
                                'font-size: '+dataWeekly.text3_size+'rem;',
                                'color: #'+dataWeekly.text3_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text3:before {',
                                'text-shadow: '+dataWeekly.text3_shadow_width+'px '+dataWeekly.text3_shadow_width+'px '+dataWeekly.text3_shadow_width+'px #'+dataWeekly.text3_shadow_color+';',
                            '}',
                            '.text4reached{', 
                                'font-size: '+dataWeekly.text4_size+'rem;',
                                'color: #'+dataWeekly.chart_reached_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text4reached:before {',
                                'text-shadow: '+dataWeekly.text4_shadow_width+'px '+dataWeekly.text4_shadow_width+'px '+dataWeekly.text4_shadow_width+'px #'+dataWeekly.text4_shadow_color+';',
                            '}',
                            '.text4{', 
                                'font-size: '+dataWeekly.text4_size+'rem;',
                                'color: #'+dataWeekly.text4_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text4:before {',
                                'text-shadow: '+dataWeekly.text4_shadow_width+'px '+dataWeekly.text4_shadow_width+'px '+dataWeekly.text4_shadow_width+'px #'+dataWeekly.text4_shadow_color+';',
                            '}',
                            '.text5{', 
                                'font-size: '+dataWeekly.text5_size+'rem;',
                                'color: #'+dataWeekly.text5_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text5:before {',
                                'text-shadow: '+dataWeekly.text5_shadow_width+'px '+dataWeekly.text5_shadow_width+'px '+dataWeekly.text5_shadow_width+'px #'+dataWeekly.text5_shadow_color+';',
                            '}',
                            ].join('\n')
                        }}>
                    </style>
                    <div className="App-bar-text">
                        {
                            campaignPeriod.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <div id={`bartext_${i+1}`} className={`App-bar-text-item _${campaignPeriod.length}`}>
                                            <style dangerouslySetInnerHTML={{
                                                    __html: [
                                                        '#bartext_'+(i+1)+' .text3reached:before { content: "'+(userProgress[i])+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        '#bartext_'+(i+1)+' .text3:before { content: "'+(userProgress[i])+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text3_stroke_width+'px #'+dataWeekly.text3_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        
                                                        '#bartext_'+(i+1)+' .text4reached._1:before { content: " / "; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        '#bartext_'+(i+1)+' .text4._1:before { content: " / "; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        
                                                        '#bartext_'+(i+1)+' .text4reached._2:before { content: "'+(campaignPeriod[i].target)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        '#bartext_'+(i+1)+' .text4._2:before { content: "'+(campaignPeriod[i].target)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        
                                                        '#bartext_'+(i+1)+' .text4reached._3:before { content: "'+(campaignTargetUnit)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        '#bartext_'+(i+1)+' .text4._3:before { content: "'+(campaignTargetUnit)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        
                                                        '#bartext_'+(i+1)+' .text4reached._4:before { content: "สแกนแล้ว"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        '#bartext_'+(i+1)+' .text4._4:before { content: "สแกนแล้ว"; position: absolute; -webkit-text-stroke: '+dataWeekly.text4_stroke_width+'px #'+dataWeekly.text4_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        

                                                        '#bartext_'+(i+1)+' .text5:before { content: "'+calcDate(item.start_date, item.end_date)+'"; position: absolute; -webkit-text-stroke: '+dataWeekly.text5_stroke_width+'px #'+dataWeekly.text5_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                                        
                                                        '#bartext_'+(i+1)+' .App-doughnutChart._reached:before { background-color: #'+dataWeekly.chart_reached_color+';}',
                                                        '#bartext_'+(i+1)+' .App-doughnutChart._unreached:before { background-color: #'+dataWeekly.chart_unreached_color+';}',
                                                        
                                                        
                                                    ].join('\n')
                                                }}>
                                            </style>
                                            <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _4':'text4 _4'}>สแกนแล้ว</span>
                                            <br/>

                                            {
                                                dataWeekly.target_unit === 'ลัง' ?
                                                calcProgressValue(userProgress[i], i)
                                                :
                                                <React.Fragment>
                                                    <span className={userProgress[i] >= campaignPeriod[i].target? 'text3reached':'text3'}>{userProgress[i]}</span>&nbsp;
                                                    {/* <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _1':'text4 _1'}> / </span>&nbsp;
                                                    <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _2':'text4 _2'}>{campaignPeriod[i].target}</span>&nbsp; */}
                                                    <span className={userProgress[i] >= campaignPeriod[i].target? 'text4reached _3':'text4 _3'}>{campaignTargetUnit}</span>
                                                </React.Fragment>
                                            }
                                            
                                            {/* call fn Doughnut chart */}
                                            { doughnutChart(userProgress[i], campaignPeriod[i].target, campaignPeriod[i].period_picture, campaignPeriod[i].start_date, i) }
                                        
                                            <span className="text5 _1">{calcDate(item.start_date, item.end_date)}</span>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="App-bar" id="app-bar" ref={barRef}>
                    <div className="App-bar-stick">
                        <div className="round-stick">
                            <div className="bar-group bar-group">
                                <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="App-status">
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            ].join('\n')
                        }}>
                    </style>
                    <div className="status-content">
                    {
                            campaignPeriod.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <p id={`periodtext_${i+1}`}>
                                            <style dangerouslySetInnerHTML={{
                                                    __html: [].join('\n')
                                                }}>
                                            </style>
                                        </p>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Weekly
