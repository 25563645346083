import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'

// import approved from '../assets/images/campaign1/bullet-green.png';
// import pending from '../assets/images/campaign1/bullet-yellow.png';


function MonthlyShort(props) {
    const barRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    const [appBar, setAppBar] = useState(0)
    // const [campaignTargetValue, setCampaignTargetValue] = useState(400.105) // campaignTarget.target
    const [campaignTargetValueBox, setCampaignTargetValueBox] = useState(0)
    const [campaignTargetValueRow, setCampaignTargetValueRow] = useState(0)
    // const [campaignTargetUnit, setCampaignTargetUnit] = useState("ลัง") // dataMonthlyshort.target_unit
    const [remainingBox, setRemainingBox] = useState(0)
    const [remainingRow, setRemainingRow] = useState(0)

    const { dataCampaign } = props;
    const dataMonthlyshort = dataCampaign.campaign.monthlyshort
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataMonthlyshort.target[0];
    let campaignTargetValue = campaignTarget.target // 16  ;
    const campaignTargetUnit = dataMonthlyshort.target_unit // "ลัง";  ;
    const userProgress = dataUser.progress // 16 ;
    const remaining = (campaignTargetValue - dataUser.progress) > 0 ? campaignTargetValue - dataUser.progress : 0

    // Images data
    const background = dataMonthlyshort.background_picture;
    const header = dataMonthlyshort.headline_picture;
    const bar = dataMonthlyshort.progressbar_picture;

    const checkTargetPos = useCallback(() => {
        // console.log('check position')
        const barWidth = barRef.current.clientWidth;
        const targetLength = dataMonthlyshort.target.length;
        const targetPosition = Math.floor(barWidth / targetLength);

        setTargetPos(targetPosition);
    },[dataMonthlyshort.target.length]);

    const calcTargetValue = useCallback(() => {
        let int_part = Math.trunc(campaignTargetValue); // returns 3
        let float_part = Number((campaignTargetValue - int_part).toFixed(2)); 
        // console.log(int_part)
        // console.log(float_part)

        if(dataMonthlyshort.product_code_type === "non_ryo") {
            setCampaignTargetValueRow(float_part * 50)
        } else if(dataMonthlyshort.product_code_type === "ryo") {
            setCampaignTargetValueRow(float_part * 20)
        } else {
            setCampaignTargetValueRow(float_part)
        }

        setCampaignTargetValueBox(int_part)
        
        // return Number.isInteger(campaignTargetUnit) ? campaignTargetValue : campaignTargetValue;
    },[campaignTargetValue, dataMonthlyshort.product_code_type])

    const calcRemainingValue = useCallback(()=>{
        let result = campaignTargetValue - userProgress
        let int_part = Math.trunc(result); // returns 3
        let float_part = Number((result - int_part).toFixed(2)); 
        // console.log('remaining:',int_part)
        // console.log('remaining:',float_part)

        if(dataMonthlyshort.product_code_type === "non_ryo") {
            let lastResult = float_part * 50
            setRemainingRow(lastResult > 0 ? lastResult : 0)
        } else {
            let lastResult = float_part * 20
            setRemainingRow(lastResult > 0 ? lastResult : 0)
        }

        setRemainingBox(int_part > 0 ? int_part : 0)
    },[campaignTargetValue, dataMonthlyshort.product_code_type, userProgress])

    const calcBarPos = useCallback(() => {
        const pb_length = barRef.current.clientWidth;
        const num_target = campaignTargetValue;

        let paint_length = 0;
        if( userProgress > num_target) {
            // Paint full bar : green
            setBarGreen(pb_length);
        } else { 
            // Paint bar : green
            paint_length = (num_target - userProgress) * (pb_length / num_target);
            setBarGreen(pb_length - paint_length);
            
        } 
        
    },[campaignTargetValue, userProgress])
    
    useEffect(() => {
        // console.log(barRef)
        // console.log(barRef.current.clientWidth)
        setAppBar(barRef.current.clientWidth)
        
        // setIsLoaded(true)
        // console.log('barRef : ',barRef.current.clientWidth);
        checkTargetPos();
        // console.log(dataCampaign)
        if(campaignTargetUnit === "ลัง") {
            calcTargetValue();
            calcRemainingValue();
        }

        calcBarPos();
    }, [barGreen, calcBarPos, campaignTarget.length, campaignTargetUnit, checkTargetPos, dataUser.checked, dataUser.pending, calcTargetValue, calcRemainingValue, userProgress])

    
    // Check Resize
    useLayoutEffect(() => {
        window.addEventListener('resize', checkTargetPos );
        window.addEventListener('resize', calcBarPos);
        checkTargetPos();
        calcBarPos();
        return () => {window.removeEventListener('resize', checkTargetPos); window.removeEventListener('resize', checkTargetPos);};
    }, [calcBarPos, checkTargetPos])


    return (
        <div className="App-monthlyshort">
            <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                <header className="App-header">
                    <img src={header} className="App-logo" alt="logo" />
                </header>
                <div className="App-content">
                    <React.Fragment>
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text1{', 
                                    'white-space: nowrap;',
                                    'font-size: '+dataMonthlyshort.text1_size+'rem;',
                                    'color: #'+dataMonthlyshort.text1_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text1:before {',
                                    'text-shadow: '+dataMonthlyshort.text1_shadow_width+'px '+dataMonthlyshort.text1_shadow_width+'px '+dataMonthlyshort.text1_shadow_width+'px #'+dataMonthlyshort.text1_shadow_color+';',
                                '}',
                                '.text2{', 
                                    'font-size: '+dataMonthlyshort.text2_size+'rem;',
                                    'color: #'+dataMonthlyshort.text2_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2:before {',
                                    'text-shadow: '+dataMonthlyshort.text2_shadow_width+'px '+dataMonthlyshort.text2_shadow_width+'px '+dataMonthlyshort.text2_shadow_width+'px #'+dataMonthlyshort.text2_shadow_color+';',
                                '}',
                                '.text3{', 
                                    'font-size: '+dataMonthlyshort.text3_size+'rem;',
                                    'color: #'+dataMonthlyshort.text3_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text3:before {',
                                    'text-shadow: '+dataMonthlyshort.text3_shadow_width+'px '+dataMonthlyshort.text3_shadow_width+'px '+dataMonthlyshort.text3_shadow_width+'px #'+dataMonthlyshort.text3_shadow_color+';',
                                '}',
                                '.text4{', 
                                    'font-size: '+dataMonthlyshort.text4_size+'rem;',
                                    'color: #'+dataMonthlyshort.text4_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text4:before {',
                                    'text-shadow: '+dataMonthlyshort.text4_shadow_width+'px '+dataMonthlyshort.text4_shadow_width+'px '+dataMonthlyshort.text4_shadow_width+'px #'+dataMonthlyshort.text4_shadow_color+';',
                                '}',
                                ].join('\n')
                                }}>
                        </style>
                        <p >
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text1._1:before { content: "'+(dataMonthlyshort.text1_content)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text1_stroke_width+'px #'+dataMonthlyshort.text1_stroke_color+'; left: 0; top: 0; z-index: -1; white-space: nowrap;}',
                                    '.text1._2:before { content: "'+(campaignTargetUnit)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text1_stroke_width+'px #'+dataMonthlyshort.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text1._3:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text1_stroke_width+'px #'+dataMonthlyshort.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                    '.text2._1:before { content: "'+(campaignTargetValue)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text2_stroke_width+'px #'+dataMonthlyshort.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text2._2:before { content: "'+(campaignTargetValueBox)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text2_stroke_width+'px #'+dataMonthlyshort.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text2._3:before { content: "'+(campaignTargetValueRow)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text2_stroke_width+'px #'+dataMonthlyshort.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                
                                    '.text3._1:before { content: "'+(dataMonthlyshort.text3_content)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text3_stroke_width+'px #'+dataMonthlyshort.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                
                                    '.text4._1:before { content: "'+(campaignTarget.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text4_stroke_width+'px #'+dataMonthlyshort.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text4._2:before { content: "คะแนน"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text4_stroke_width+'px #'+dataMonthlyshort.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    '.text4._3:before { content: "'+(campaignTarget.reward_name)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text4_stroke_width+'px #'+dataMonthlyshort.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    
                                ].join('\n')
                            }}>
                            </style>
                            <span className='text1 _1'>{ dataMonthlyshort.text1_content }</span>&nbsp;&nbsp;
                            {
                                campaignTargetUnit === "ลัง" ?
                                    <React.Fragment>
                                        <span className='text2 _2'>{ campaignTargetValueBox }</span>&nbsp;
                                        <span className='text1 _2'>{ campaignTargetUnit }</span>&nbsp;&nbsp;
                                        {
                                            campaignTargetValueRow > 0 ?
                                                <React.Fragment>
                                                    <span className='text2 _3'>{ campaignTargetValueRow }</span>&nbsp;
                                                    <span className='text1 _3'>แถว</span>
                                                </React.Fragment>
                                            : null
                                        }
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        <span className='text2 _1'>{ campaignTargetValue }</span>&nbsp;
                                        <span className='text1 _2'>{ campaignTargetUnit }</span>
                                    </React.Fragment>
                            } &nbsp;
                        <br/>
                            <span className='text3 _1'>{ dataMonthlyshort.text3_content }</span>&nbsp;
                            {
                                (campaignTarget.reward_type === 'sfdc') ? 
                                <React.Fragment>
                                    <span className='text4 _1'>{ (campaignTarget.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)} </span>&nbsp;
                                    <span className='text4 _2'>คะแนน</span> 
                                </React.Fragment>
                                : 
                                <span className='text4 _3'>
                                    { campaignTarget.reward_name }
                                </span>
                            }
                            
                        </p>
                    </React.Fragment>
                </div>
                
                <div className="App-bar" id="app-bar" ref={barRef}>
                    <div className="App-bar-stick">
                        <div className="round-stick">
                            <div className="bar-group bar-group">
                                <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                            </div>
                        </div>
                    </div>
                    <img src={bar} className="App-bar-border" alt="bar" />
                    
                    <div className="App-target-box" style={{left: targetPos, width: appBar / 2.4, height: appBar / 2.4,}} >{
                            ( userProgress >= campaignTargetValue ) ? 
                                <img src={dataMonthlyshort.target_picture_reached} className="App-target" alt="target-reached" />
                            :
                                <img src={dataMonthlyshort.target_picture_not_reached} className="App-target" alt="target-not-reached" />   
                        }
                    </div>
                </div>
                <div className="App-status">
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            '.text5{', 
                                'font-size: '+dataMonthlyshort.text5_size+'rem;',
                                'color: #'+dataMonthlyshort.text5_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text5:before {',
                                'text-shadow: '+dataMonthlyshort.text5_shadow_width+'px '+dataMonthlyshort.text5_shadow_width+'px '+dataMonthlyshort.text5_shadow_width+'px #'+dataMonthlyshort.text5_shadow_color+';',
                            '}',
                            '.text5._1:before { content: "'+(remaining)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text5_stroke_width+'px #'+dataMonthlyshort.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                            '.text5._2:before { content: "'+(remainingBox)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text5_stroke_width+'px #'+dataMonthlyshort.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                            '.text5._3:before { content: "'+(remainingRow)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text5_stroke_width+'px #'+dataMonthlyshort.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                
                            '.text6{', 
                                'font-size: '+dataMonthlyshort.text6_size+'rem;',
                                'color: #'+dataMonthlyshort.text6_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text6:before {',
                                'text-shadow: '+dataMonthlyshort.text6_shadow_width+'px '+dataMonthlyshort.text6_shadow_width+'px '+dataMonthlyshort.text6_shadow_width+'px #'+dataMonthlyshort.text6_shadow_color+';',
                            '}',
                            '.text6._1:before { content: "เหลืออีก"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text6_stroke_width+'px #'+dataMonthlyshort.text6_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                            '.text6._2:before { content: "'+(campaignTargetUnit)+'"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text6_stroke_width+'px #'+dataMonthlyshort.text6_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                            '.text6._3:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataMonthlyshort.text6_stroke_width+'px #'+dataMonthlyshort.text6_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                            
                            ].join('\n')
                        }}>
                    </style>
                    <div className="status-content">
                        {/* <img src={approved} className="status-bullet" alt="approved" /> */}
                        <span className="text6 _1">เหลืออีก</span>&nbsp;
                        {
                            campaignTargetUnit === "ลัง" ?
                                <React.Fragment>
                                    <span className='text5 _2'>{ remainingBox }</span>&nbsp;
                                    <span className='text6 _2'>{ campaignTargetUnit }</span>&nbsp;&nbsp;
                                    {
                                        remainingRow > 0 ?
                                        <React.Fragment>
                                            <span className='text5 _3'>{ remainingRow }</span>&nbsp;
                                            <span className='text6 _3'>แถว</span>
                                        </React.Fragment>
                                        : null
                                    }
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <span className="text5 _1">{remaining}</span>&nbsp;
                                    <span className="text6 _2">{campaignTargetUnit}</span>
                                </React.Fragment>
                        } &nbsp;
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MonthlyShort
