import React, { useState, useEffect } from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Redirect,
// } from "react-router-dom";
import './App.scss';

import Barcode from "./pages/Barcode";
import Barcode2 from './pages/Barcode2';
import Monthly from "./pages/Monthly";
import Monthly2 from "./pages/Monthly2";
import Task from "./pages/Task";
import Task2 from "./pages/Task2";
import Leader from "./pages/Leader";
import Team from "./pages/Team";
import MonthlyShort from './pages/MonthlyShort';
import MonthlyShort2 from './pages/MonthlyShort2';
import Weekly from './pages/Weekly';
import Weekly2 from './pages/Weekly2';
import MainError from './pages/MainError';
import Leader2 from './pages/Leader2';
import Stamp from './pages/Stamp';

// import data from './datamockup.json';
// import data from './datamockup2.json';

function App() {
  const [hasError, setHasError] = useState(false);
  const [dataCampaign, setDataCampaign] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

 
  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const query = url.searchParams.get("query");
    const urlPathname = url.pathname.split('/');
    const idFromPathname = urlPathname[urlPathname.length -1];
    // console.log(url.pathname)
    console.log('Edit.V.12.180722, campaign id:',idFromPathname)

    // Fetch API
    async function fetchDataCampaign () {
      const queryData = query;
      const queryFinal = queryData.split(' ').join('%2B');
      const campaignID = idFromPathname;
      const apiXKey = 'b5e64ba6-1d27-4c40-bc65-2e686e394d62';

      const apiPath = process.env.REACT_APP_API

      const res = await fetch(`${apiPath}`, {
          method: 'POST',
          body: JSON.stringify({
              "query": queryFinal,
              "campaign_id": campaignID
          }),
          headers: {
              "x-application-secret-key": apiXKey,
              "Content-Type": "application/json",
              "Accept": "application/json"
          }
      }); 

      res
        .json()
        .then(res => {
          if (res.success === 0 || res === null || res === undefined ) {
            setIsLoaded(true);
            setHasError(true);
          }

            setIsLoaded(true);
            setDataCampaign(res); // from API
            // setDataCampaign(data); // from local test
        })
        .catch(err => {
          console.log(err);
          setIsLoaded(true);
          setHasError(true);
        });
    }

    // Call Fetch API
    fetchDataCampaign();
  }, [])

  // Check loading
  if(!isLoaded) {
    return (
      <div className="App">
        <div className="App-loading">
          <p>...Loading...</p>
        </div>
      </div>
    );

   }  
   
  // Check error campaign
  if(hasError){
    return (
      <div className="App">
        <div className="App-error">
          {/* <p>campaign ID ไม่ถูกต้อง</p> */}
          <p>พบกิจกรรมใหม่เร็วๆนี้</p>
        </div>
      </div>
    );
  } 

  // Check obj empty of dataCampaign
  if(Object.keys(dataCampaign).length === 0){
    return (
      <div className="App">
        <div className="App-error">
          <p>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
        </div>
      </div>
    );
  } 

  // Check dataCampaign has data
  if(dataCampaign) {
    // console.warn(Object.keys(dataCampaign).length)
    console.log('campaign type:',dataCampaign.campaign.type, ',version:',dataCampaign.version)
    const campaignType = dataCampaign.campaign.type || null;
    const campaignVersion = dataCampaign.version || null;

    // Get campaign page
    const getCampaignType = () => {
      if(campaignType === 'barcode') {
        if(campaignVersion === 2) {
          return <Barcode2 dataCampaign={dataCampaign} />;
        } else {
          return <Barcode dataCampaign={dataCampaign} />;
        }

      } else if(campaignType === 'monthly') {
        if(campaignVersion === 2) {
          return <Monthly2 dataCampaign={dataCampaign} />;
        } else {
          return <Monthly dataCampaign={dataCampaign} />;
        }

      } else if(campaignType === 'task') {
        if(campaignVersion === 2) {
          return <Task2 dataCampaign={dataCampaign} />;
        } else {
          return <Task dataCampaign={dataCampaign} />;
        }

      } else if(campaignType === 'leaderboard') {
        if(campaignVersion === 2) {
          return <Leader2 dataCampaign={dataCampaign} />;
        } else {
          return <Leader dataCampaign={dataCampaign} />;
        }

      } else if(campaignType === 'team') {
        return <Team dataCampaign={dataCampaign} />;

      } else if(campaignType === 'monthlyshort') {
        if(campaignVersion === 2) {
          return <MonthlyShort2 dataCampaign={dataCampaign} />;
        } else {
          return <MonthlyShort dataCampaign={dataCampaign} />;
        }

      } else if(campaignType === 'weekly') {
        if(campaignVersion === 2) {
          return <Weekly2 dataCampaign={dataCampaign} />;
        } else {
          return <Weekly dataCampaign={dataCampaign} />;
        }

      } else if(campaignType === 'stamp') {
        return <Stamp dataCampaign={dataCampaign} />;

      } else {
        return <MainError dataCampaign={dataCampaign} />;
      }
    }

    return (
      <div className="App">
        { getCampaignType() }
        {/* <Router>
          <Switch>
                <Route exact path="/" render={()=> getCampaignType() } />

                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Router> */}
      </div>
    );
  }
    
  
}

export default App;
