import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'
import moment from 'moment'
// import background from '../assets/images/campaign1/bg.jpg';
// import header from '../assets/images/campaign1/header.png';
// import bar from '../assets/images/campaign1/bar.png';
// import target from '../assets/images/campaign1/target.png';
// import target1000 from '../assets/images/campaign1/04-2_Target.png';
// import approved from '../assets/images/campaign1/bullet-green.png';
// import pending from '../assets/images/campaign1/bullet-yellow.png';


function Barcode2(props) {
    const barRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    const [barYellow, setBarYellow] = useState(0)
    // const [appBar, setAppBar] = useState(0)

    const { dataCampaign } = props;
    const dataBarcode = dataCampaign.campaign.barcode
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataBarcode.target;
    const userTarget = dataUser.target;
    const campaignNoTarget = dataBarcode.no_targets; // 1
    // const userTarget = [2, 3, 4, 10, 12];
    // const userChecked = dataUser.checked;
    // const userPending = dataUser.pending;

    // Images data
    // const background = dataBarcode.background_picture;
    // const header = dataBarcode.headline_picture;
    // const bar = dataBarcode.progressbar_picture;
    const banner = dataBarcode.background_picture;
    const updateTime = dataUser.updated_time;
    const startDate = dataCampaign.campaign.start_date;
    const endDate = dataCampaign.campaign.end_date;

    // User data
    // const barGreen = dataUser;


    // const textStyle = (numTextPosition) => {
    //     const textPosition = numTextPosition;

    //     // const xxxx = textPosition;
    //     const txtColor = '#'+dataBarcode[textPosition+'_color'];
    //     const txtSize = dataBarcode[textPosition+'_size']+'rem';
    //     const txtShadowWidth = dataBarcode[textPosition+'_shadow_width']+'px';
    //     const txtShadowColor = '#'+dataBarcode[textPosition+'_shadow_color'];
    //     const txtStrokeWidth = dataBarcode[textPosition+'_stroke_width']+'px';
    //     const txtStrokeColor = '#'+dataBarcode[textPosition+'_stroke_color'];

    //     const objStyle = {
    //         color: txtColor,
    //         textShadow: txtShadowWidth+' '+txtShadowWidth+' '+txtShadowWidth+' '+txtShadowColor,
    //         fontSize: txtSize,
    //         WebkitTextStroke: txtStrokeWidth+' '+txtStrokeColor,
    //     }
    //     return objStyle;
    // }

    const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']
    const calcDurationDate = (valStart, valEnd) => {
        const startDay = moment(valStart).format('D')
        const endDay = moment(valEnd).format('D')
        const startMonth = Number(moment(valStart).format('MM'))
        const endMonth = Number(moment(valEnd).format('MM'))
        const fullStartYear = Number(moment(valStart).format('YYYY')) + 543
        const fullEndYear = Number(moment(valEnd).format('YYYY')) + 543
        const startYear = fullStartYear - 2500
        const endYear = fullEndYear - 2500
// console.log(startMonth, endMonth)

        if(startMonth === endMonth) {
            return (startDay+' - '+endDay+' '+monthArr[endMonth -1]+' '+endYear)
        } else {
            if(startYear === endYear) {
                return (startDay+' '+monthArr[startMonth-1]+' - '+endDay+' '+monthArr[endMonth-1]+' '+endYear)
            } else {
                return (startDay+' '+monthArr[startMonth-1]+' '+startYear+' - '+endDay+' '+monthArr[endMonth-1]+' '+endYear)
            }
        }

    }

    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' '+dataTime)
    }


    const checkTargetPos = useCallback(() => {
        // console.log('check position')
        const barWidth = barRef.current.clientWidth;
        const targetLength = campaignTarget.length;
        const targetPosition = Math.floor(barWidth / targetLength);

        setTargetPos(targetPosition);
    },[campaignTarget.length]);

    const calcBarPos = useCallback(() => {
        // let userTargetAll = userTarget.reduce(function(acc, val) { return acc + val; }, 0)
        const pb_length = barRef.current.clientWidth;
        const num_target = userTarget.length;
        let sec_length =  pb_length/num_target;
        let approved = dataUser.checked;
        let unapproved = dataUser.pending;
        // let approved = 5;
        // let unapproved = 5;
        
        let paint_length = 0;
        let color = "green";
        for(let ind = 0; ind < num_target; ind++) {
            // console.warn('userTarget',userTarget[ind])
            let userTargetPrev = (userTarget[ind-1] ? userTarget[ind-1] : 0);
            let userTargetNext = (userTarget[ind+1] ? userTarget[ind+1] : 0);
            let userTargetCurrent = userTarget[ind];

            if( approved > userTarget[ind]) {
                // Paint full bar : green
                setBarGreen(Math.abs((ind+1)*sec_length));
                // console.warn('>>>>>>>> full <<<<<<<< ROUND:',ind)
            } else { 
                // console.warn('>>>>>>>> half <<<<<<<< ROUND:',ind)
                // Paint bar : green
                paint_length = ((approved - userTargetPrev) * sec_length / (userTargetCurrent - userTargetPrev));
                setBarGreen(Math.abs((ind*sec_length) + paint_length));

                if(color === 'yellow') {
                    break;
                } else {
                    color = 'yellow';

                    approved += unapproved  ;  // บวกค่าที่รอตรวจเข้าไปกับค่าที่ตรวจแล้ว 

                    let barYellowCurrent = 0;
                    let barYellowNext = 0;
                    let unapprovedBalance = unapproved - (approved - userTargetCurrent );
// console.log('approved ', approved)
// console.log('userTargetPrev ', userTargetPrev)
// console.log('userTargetCurrent ', userTargetCurrent)
// console.log('userTargetNext ', userTargetNext)
// console.log('unapprovedBalance = ', unapprovedBalance)
                    if(approved > userTargetCurrent) {
                        barYellowCurrent = Math.abs(unapprovedBalance) * Math.abs(sec_length / (userTargetCurrent - userTargetPrev));
                        barYellowNext =  Math.abs(approved - (userTargetCurrent ))  * Math.abs(sec_length / (userTargetNext - userTargetCurrent));
                        // paint_length = barYellowNext

// console.log('_barYellowNext ', barYellowNext)
// console.log('_barYellowCurrent',barYellowCurrent)
                    } else {
                        barYellowCurrent = Math.abs(unapproved) * (sec_length / (userTargetCurrent - userTargetPrev));
                        
                        // paint_length = barYellowCurrent

// console.log('*barYellowNext ', barYellowNext)
// console.log('*barYellowCurrent',barYellowCurrent)
                    }

                    paint_length = barYellowCurrent + barYellowNext
                    setBarYellow(paint_length)
                }

                
                break;
            } 
        }
        
        // console.log('barWidth', pb_length,'px -------------')
        // console.log('barGreen', barGreen,'px')
        // console.log('barYellow', barYellow,'px')
    },[dataUser.checked, dataUser.pending, userTarget])
    
    useEffect(() => {
        // console.log(barRef)
        // console.log(barRef.current.clientWidth)
        // setAppBar(barRef.current.clientWidth)
        
        // setIsLoaded(true)
        // console.log('barRef : ',barRef.current.clientWidth);
        checkTargetPos();
        // console.log(dataCampaign)
        calcBarPos();
    }, [barGreen, barYellow, calcBarPos, campaignTarget.length, checkTargetPos, dataUser.checked, dataUser.pending, userTarget])

    
    // Check Resize
    useLayoutEffect(() => {
        window.addEventListener('resize', checkTargetPos );
        window.addEventListener('resize', calcBarPos);
        checkTargetPos();
        calcBarPos();
        return () => {window.removeEventListener('resize', checkTargetPos); window.removeEventListener('resize', checkTargetPos);};
    }, [calcBarPos, checkTargetPos])


    if(campaignTarget.length === userTarget.length) {
        return (
            <div className="App-bar-V2">
                <header className="App-banner">
                    {
                        banner ? 
                        <img src={banner} alt="banner" />
                        :
                        <div className="noimg">
                            No Image
                        </div>
                        
                    }
                </header>
                <div className="App-content">
                    <p className={`texth ${campaignNoTarget > 1 ? 'multiple-targets' : 'single-target'}`}>
                        {
                            campaignNoTarget > 1 ? 'เพียงสะสมครบตามเป้าหมายย่อย' : 'ตั้งแต่ '+ calcDurationDate(startDate, endDate)
                        }
                    </p>
                    {
                        campaignTarget.map((item,i)=>{
                            return (
                                <React.Fragment key={i}>
                                    <p key={i} id={`text-group-${i+1}`} className="text-group">
                                        <span className='text1 _1'>ครบ</span>&nbsp;&nbsp;
                                        <span className='text2 _1'>{ userTarget[i] }</span>&nbsp;&nbsp;
                                        { 
                                            (i === 0) ? <span className='text1 _2'>แถว รับ</span> : <span className='text1 _3'>แถว รับเพิ่ม</span>
                                        }&nbsp;&nbsp;
                                        {
                                            (campaignTarget[i].reward_type === 'sfdc') ? 
                                            <React.Fragment>
                                                <span className='text2 _2'>{ (campaignTarget[i].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)} </span>&nbsp;
                                                <span className='text1 _4'>คะแนน</span> 
                                            </React.Fragment>
                                            : 
                                            <span className='text2 _3'>
                                                { campaignTarget[i].reward_name }
                                            </span>
                                        }
                                        
                                    </p>
                                </React.Fragment>
                            );
                        })
                    }
                </div>
                {/* {
                    (dataUser.text_extra) ? 
                        <div className="App-text-extra">
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.textx{', 
                                        'font-size: '+dataBarcode.textx_size+'rem;',
                                        'color: #'+dataBarcode.textx_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                    '.textx:before {',
                                        'text-shadow: '+dataBarcode.textx_shadow_width+'px '+dataBarcode.textx_shadow_width+'px '+dataBarcode.textx_shadow_width+'px #'+dataBarcode.textx_shadow_color+';',
                                    '}',
                                    '.textx:before { content: "'+(dataUser.text_extra)+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.textx_stroke_width+'px #'+dataBarcode.textx_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                    ].join('\n')
                                }}>
                            </style>
                            <span className="textx">{dataUser.text_extra}</span>
                        </div>
                    : null
                } */}
                
                {
                    campaignNoTarget > 1 ? 
                    <div className="App-total">
                        <p className="texth">ยอดสะสม</p>
                        <p>
                            <span className='hilight texth'>{ dataUser.checked }&nbsp;</span>
                            <span className='normal texth'>แถว</span>
                        </p>
                    </div> 
                    : 
                    <div className="App-bar-status">
                        <p>ยอดสะสม</p>
                        <p>
                            <span>{dataUser.checked}</span> / {userTarget[0]} แถว
                        </p>
                    </div>
                }
                <br/>
                {/* <div className="App-total">
                    <p className="texth">ยอดสะสม</p>
                    <p>
                        <span className='hilight texth'>{ dataUser.checked }&nbsp;</span>
                        <span className='normal texth'>แถว</span>
                    </p>
                </div> */}
                <div className={`App-bar-section ${campaignNoTarget > 1 ? 'multiple-targets' : 'single-target'}`}>
                    <div className="App-bar" id="app-bar" ref={barRef}>
                        <div className="App-bar-border">
                            {
                                campaignNoTarget > 1 ? <p>เป้าหมาย</p> : null
                            }
                        </div>
                        <div className="App-bar-stick">
                            <div className="round-stick">
                                {/* <div className="bar-group bar-group"> */}
                                    <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                    {/* <div id="bar-stick-yellow"  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div> */}
                                {/* </div> */}
                                {/* {
                                    
                                    userTarget.map((item,i)=>{
                                        return(
                                            <div className={'bar-group bar-group-'+(i)} key={i}>
                                                <div id={'bar-stick-green-'+i} className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                                <div id={'bar-stick-yellow-'+i}  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                            </div>)
                                        }
                                    )
                                } */}
                            </div>
                        </div>

                        {
                            campaignNoTarget > 1 ? 
                                campaignTarget.map((item,i)=>{
                                    return (
                                        <div className="App-target-box" style={{left: targetPos * (i+1)}} key={i} >
                                            {
                                                (dataUser.checked >= dataUser.target[i]) ? 
                                                    <div className="target-reached">
                                                        <p>{dataUser.target[i]}</p>
                                                    </div>
                                                    // <img src={item.target_picture_reached} className="App-target" alt="target-reached" />
                                                :
                                                    <div className="target-not-reached">
                                                        <p>{dataUser.target[i]}</p>
                                                    </div>
                                                    // <img src={item.target_picture_not_reached} className="App-target" alt="target-not-reached" />   
                                            }
                                            
                                            {/* <img src={target1000} className="App-target" alt="target" /> */}
                                        </div>
                                    );
                                })
                            : null
                        }
                    </div>
                </div>
                <div className="App-status">
                    <div className="status-content">
                        <span className="text3 _4">ข้อมูล ณ วันที่ {calcUpdatedDate(updateTime)} น.</span>
                    </div>
                </div>
            </div>
        )
    } else {
        <div className="App-error">
          <p>ข้อมูลระหว่าง campaign และ user ไม่ตรงกัน</p>
        </div>
    }

}

export default Barcode2
