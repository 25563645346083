import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'
import moment from 'moment';

import progressBullet from '../assets/images/campaign1/bullet-green.png';


function Monthly(props) {
    const barRef = useRef(null);
    const contentRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    const [appBar, setAppBar] = useState(0)
    const [heightContent, setHeightContent] = useState(0);
 
    const { dataCampaign } = props;
    const dataMonthly = dataCampaign.campaign.monthly
    const start_date = dataCampaign.campaign.start_date
    const end_date = dataCampaign.campaign.end_date
    const product_code_type = dataCampaign.campaign.monthly.product_code_type // 'non_ryo' 
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataMonthly.target;
    const campaignTargetUnit = dataMonthly.target_unit;
    
    const userProgressMonth = dataUser.progress_month;
    const userProgress = dataUser.progress;

    // Images data
    const background = dataMonthly.background_picture;
    const header = dataMonthly.headline_picture;
    const bar = dataMonthly.progressbar_picture;

    // Campaign duration
    let start_obj = moment(start_date); 
    let end_obj = moment(end_date)
    let campaignDuration = (end_obj.year()-start_obj.year())*12 + (end_obj.month()-start_obj.month()) + 1;

    const campaignDurationFn = () => {
        let campaignDurationArr = [];
        for(let i=0; i<campaignDuration; i++) {
            campaignDurationArr.push(<React.Fragment key={i} >
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            '.text7{', 
                                'font-family: "db_heaventlight";',
                                'font-size: '+dataMonthly.text7_size+'rem;',
                                'color: #'+dataMonthly.text7_color+';}',   
                            '.text7_month_size{', 
                                'font-family: "db_heaventbold";',
                                'font-size: '+dataMonthly.text7_month_size+'rem;',
                                'color: #'+dataMonthly.text7_color+';}',
                            
                            '.text8{', 
                                'font-size: '+dataMonthly.text8_size+'rem;',
                                'color: #'+dataMonthly.text8_color+';}',    
                            
                            '.box{',
                                'background-color: #'+dataMonthly.box7_color+';',
                                'border: '+dataMonthly.border7_width+'px solid #'+dataMonthly.border7_color+';}',
                            
                            ].join('\n')
                        }}>
                    </style>
                    <div className={`box _${i}`}>
                        <p className="text7">เดือน</p>
                        <p className="text7_month_size">{(i===0) ? dataMonthly.text7_month1 : (i===1) ? dataMonthly.text7_month2 : dataMonthly.text7_month3}</p>
                        <p className="text7">คุณทำได้</p>
                        {
                            (campaignTargetUnit === 'ลัง') && !checkNumber(userProgressMonth[i]) ? 
                                // userProgressMonth[i] === null ? <React.Fragment><p><span className="text8">0 </span><span className="text7">{dataMonthly.target_unit}</span></p></React.Fragment> 
                                
                                !!userProgressMonth[i] ? 
                                    getBoxNRow3(userProgressMonth[i]) 
                                :
                                <React.Fragment>
                                    <p>
                                        <span className="text8">0 </span>
                                        <span className="text7">{dataMonthly.target_unit}</span>
                                    </p>
                                </React.Fragment> 
                            :
                            <p><span className="text8">{userProgressMonth[i] === null ? 0 : userProgressMonth[i]}</span> <span className="text7">{dataMonthly.target_unit}</span></p>
                        }
                    </div>
                </React.Fragment>)
        }
        return campaignDurationArr;
    }

    const calcTargetValueBox = (val) => {
        let int_part = Math.trunc(val); // returns 3
        // console.log('target box',int_part)
        let result = int_part;
        
        return result;
    }

    const calcTargetValueRow = (val) => {
        let int_part = Math.trunc(val); // returns 3
        let float_part = Number((val - int_part).toFixed(2)); 
        // console.log('target box',int_part)
        // console.log('target row',int_part)
        // console.log('product_code_type',product_code_type)
        let result = 0;

        if(product_code_type === "non_ryo") {
            result = float_part * 50
        } else if(product_code_type === "ryo") {
            result = float_part * 20
        } else {
            result = float_part
        }

        // switch (product_code_type) {
        //     case "non_ryo":
        //         result = float_part * 50
        //         break;
        
        //     case "ryo":
        //         result = float_part * 20
        //         break;
            
        //     default:
        //         result = float_part
        //         break;
        // }

        return result;
    }

    const checkTargetPos = useCallback(() => {
        // console.log('check position')
        const barWidth = barRef.current.clientWidth;
        const targetLength = campaignTarget.length;
        const targetPosition = Math.floor(barWidth / targetLength);

        setTargetPos(targetPosition);
    },[campaignTarget.length]);

    const calcBarPos = useCallback(() => {
        // let userProgressMonthAll = userProgressMonth.reduce(function(acc, val) { return acc + val; }, 0)
        const pb_length = barRef.current.clientWidth;
        const num_target = campaignTarget.length;
        let sec_length =  pb_length/num_target;
        let progress = userProgress;
        let paint_length = 0;

        for(let ind = 0; ind < num_target; ind++) {
            
            let campaignTargetCurrent = campaignTarget[ind].target;
            let campaignTargetPrev = (campaignTarget[ind-1] !== undefined ? campaignTarget[ind-1].target : 0);

            if( progress > campaignTargetCurrent) {
                // Paint full bar : green
                setBarGreen(Math.abs((ind+1)*sec_length));
            } else { 
                // Paint bar : green
                paint_length = (progress - campaignTargetPrev) * sec_length / (campaignTargetCurrent - campaignTargetPrev);
                setBarGreen(Math.abs((ind*sec_length) + paint_length));
                
                break;
            } 
        }
        
    },[campaignTarget, userProgress])

    // Check Number Float
    const checkNumber = (n) => {
        return n % 1 === 0
    }

    // Get target for box & row
    const getBoxNRow1 = (val) => {
        let getBox = parseInt(val) 
        // let getDigit = val.toString()
        let getRow = (Math.round(val*1000)%1000)/20;
        // let getRow = Number(getDigit.substring(getDigit.length - 3)) * 50
        // console.log(getBox,Number(getDigit.substring(getDigit.length - 3)), getRow)
        
        return (
            <React.Fragment>

                <style dangerouslySetInnerHTML={{
                    __html: [
                        '#text-group-0 .text1._1:before { content: "ให้ถึงเป้า"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        '#text-group-0 .text1._2:before { content: "ลัง"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        '#text-group-0 .text1._3:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        '#text-group-0 .text3._1:before { content: "'+(getBox)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        '#text-group-0 .text3._2:before { content: "'+(getRow)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                    ].join('\n')
                }}>
                </style>
                <span className='text1 _1'>ให้ถึงเป้า</span>&nbsp;
                <span className='text3 _1'>{ getBox}</span>&nbsp;
                <span className='text1 _2'>ลัง</span>&nbsp;

                {
                    getRow > 0 ?
                        <React.Fragment>
                        <span className='text3 _2'>{ getRow } </span>&nbsp;
                        <span className='text1 _3'>แถว</span> 
                        </React.Fragment>
                    : null
                }
            </React.Fragment>
        )
    }
    const getBoxNRow2 = (val) => {
        // let getBox = parseInt(val)
        // let getDigit = val.toString()
        // let getRow = (Math.round(val*1000)%1000)/20;
        // let getRow = Number(getDigit.substring(getDigit.length - 3)) * 50
        // console.log(getBox,Number(getDigit.substring(getDigit.length - 3)), getRow)
        
        return (
            <React.Fragment>

                <style dangerouslySetInnerHTML={{
                    __html: [
                        '.text4{', 
                            'font-size: '+dataMonthly.text4_size+'rem;',
                            'color: #'+dataMonthly.text4_color+';',
                            'text-shadow: '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px #'+dataMonthly.text4_shadow_color+';',
                            'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                        '.text4:before {',
                            'text-shadow: '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px #'+dataMonthly.text4_shadow_color+';',
                        '}',
                        '.text4._1:before { content: "'+(calcTargetValueBox(val))+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text4_stroke_width+'px #'+dataMonthly.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        '.text4._2:before { content: "'+(calcTargetValueRow(val))+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text4_stroke_width+'px #'+dataMonthly.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        
                        '.text5{', 
                            'font-size: '+dataMonthly.text5_size+'rem;',
                            'color: #'+dataMonthly.text5_color+';',
                            'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                        '.text5:before {',
                            'text-shadow: '+dataMonthly.text5_shadow_width+'px '+dataMonthly.text5_shadow_width+'px '+dataMonthly.text5_shadow_width+'px #'+dataMonthly.text5_shadow_color+';',
                        '}',
                        '.text5._1:before { content: "ลัง"; position: absolute; -webkit-text-stroke: '+dataMonthly.text5_stroke_width+'px #'+dataMonthly.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                        '.text5._2:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataMonthly.text5_stroke_width+'px #'+dataMonthly.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                        
                        
                        ].join('\n')
                }}>
                </style>
                <div className="status-text">
                    {/* <span className="text4 _1">{getBox}&nbsp;</span> */}
                    <span className="text4 _1">{calcTargetValueBox(val)}</span> &nbsp;
                    <span className="text5 _1">ลัง</span>&nbsp;
                    {/* <span className="text4 _2">{getRow}&nbsp;</span> */}
                    {
                        calcTargetValueRow(val) > 0 ?
                            <React.Fragment>
                            <span className="text4 _2">{calcTargetValueRow(val)}&nbsp;</span>
                            <span className="text5 _2">แถว</span>
                            </React.Fragment>
                        : null
                    }
                </div>
            </React.Fragment>
        )
    }
    const getBoxNRow3 = (val) => {
        // let getBox = parseInt(val)
        // let getDigit = val.toString()
        // let getRow = (Math.round(val*1000)%1000)/20;
        // let getRow = Number(getDigit.substring(getDigit.length - 3)) * 50
        // console.log(getBox,Number(getDigit.substring(getDigit.length - 3)), getRow)
        
        return (
            <React.Fragment>

                <style dangerouslySetInnerHTML={{
                    __html: [
                        '.text7{', 
                            'font-family: "db_heaventlight";',
                            'font-size: '+dataMonthly.text7_size+'rem;',
                            'color: #'+dataMonthly.text7_color+';}',   
                        '.text8{', 
                            'font-size: '+dataMonthly.text8_size+'rem;',
                            'color: #'+dataMonthly.text8_color+';}',
                    ].join('\n')
                }}>
                </style>
                <p>
                    {/* <span className="text8">{getBox}</span> &nbsp; */}
                    <span className="text8">{calcTargetValueBox(val)}</span> &nbsp;
                    <span className="text7">ลัง</span>&nbsp;
                    {/* <span className="text8">{getRow}</span> &nbsp; */}
                    {
                        calcTargetValueRow(val) > 0 ?
                            <React.Fragment>
                            <span className="text8">{calcTargetValueRow(val)}</span> &nbsp;
                            <span className="text7">แถว</span>
                            </React.Fragment>
                        : null
                    }
                </p>
                    
            </React.Fragment>
        )
    }
    
    useEffect(() => {
        setAppBar(barRef.current.clientWidth)
        
        // setIsLoaded(true)
        checkTargetPos();
        // console.log(dataCampaign)
        calcBarPos();
        // console.log(contentRef.current.childNodes[1].clientHeight)
        // setHeightContent(contentRef.current.childNodes[1].clientHeight)
        setHeightContent(32);  // 32 is 16px * 2

        // console.log(window.innerHeight)

    }, [barGreen, calcBarPos, campaignTarget.length, checkTargetPos, dataUser.checked, dataUser.pending, userProgressMonth])
    

    
    // Check Resize
    useLayoutEffect(() => {
        window.addEventListener('resize', checkTargetPos );
        window.addEventListener('resize', calcBarPos);
        checkTargetPos();
        calcBarPos();
        return () => {window.removeEventListener('resize', checkTargetPos); window.removeEventListener('resize', checkTargetPos);};
    }, [calcBarPos, checkTargetPos])



        return (
            <div className="App-monthly">
                <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                    <header className="App-header">
                        <img src={header} className="App-logo" alt="logo" />
                    </header>
                    <div className="App-content" style={{minHeight: heightContent * 5+'px'}}>
                        {
                            campaignTarget.length > 1 ?
                                campaignTarget.map((item,i)=>{
                                    return (
                                        <React.Fragment key={i}>
                                            <style dangerouslySetInnerHTML={{
                                                __html: [
                                                    '.text1{', 
                                                        'font-size: '+dataMonthly.text1_size+'rem;',
                                                        'color: #'+dataMonthly.text1_color+';',
                                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                                    '.text1:before {',
                                                        'text-shadow: '+dataMonthly.text1_shadow_width+'px '+dataMonthly.text1_shadow_width+'px '+dataMonthly.text1_shadow_width+'px #'+dataMonthly.text1_shadow_color+';',
                                                    '}',
                                                    '.text2{', 
                                                        'font-size: '+dataMonthly.text2_size+'rem;',
                                                        'color: #'+dataMonthly.text2_color+';',
                                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                                    '.text1:before {',
                                                        'text-shadow: '+dataMonthly.text2_shadow_width+'px '+dataMonthly.text2_shadow_width+'px '+dataMonthly.text2_shadow_width+'px #'+dataMonthly.text2_shadow_color+';',
                                                    '}',
                                                    '.text3{', 
                                                        'font-size: '+dataMonthly.text3_size+'rem;',
                                                        'color: #'+dataMonthly.text3_color+';',
                                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                                    '.text3:before {',
                                                        'text-shadow: '+dataMonthly.text3_shadow_width+'px '+dataMonthly.text3_shadow_width+'px '+dataMonthly.text3_shadow_width+'px #'+dataMonthly.text3_shadow_color+';',
                                                    '}',
                                                    ].join('\n')
                                                    }}>
                                            </style>
                                            <p key={i} id={`text-group-${i+1}`} ref={contentRef}>
                                                <style dangerouslySetInnerHTML={{
                                                    __html: [
                                                        '#text-group-'+(i+1)+' .text1._1:before { content: "'+(dataMonthly.text2_include_line_no ?(i+1)+'. ครบ' : 'ครบ')+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text1._2:before { content: "'+(campaignTargetUnit)+' รับ"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text1._3:before { content: "'+(campaignTargetUnit)+' รับ"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text1._4:before { content: "คะแนน"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        
                                                        '#text-group-'+(i+1)+' .text2._1:before { content: "'+(dataMonthly.text2_include_line_no ?(i+1)+'. ครบ' : 'ครบ')+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text2_stroke_width+'px #'+dataMonthly.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text2._2:before { content: "'+(campaignTargetUnit)+' รับ"; position: absolute; -webkit-text-stroke: '+dataMonthly.text2_stroke_width+'px #'+dataMonthly.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text2._3:before { content: "'+(campaignTargetUnit)+' รับ"; position: absolute; -webkit-text-stroke: '+dataMonthly.text2_stroke_width+'px #'+dataMonthly.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text2._4:before { content: "คะแนน"; position: absolute; -webkit-text-stroke: '+dataMonthly.text2_stroke_width+'px #'+dataMonthly.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text2._5:before { content: "ลัง"; position: absolute; -webkit-text-stroke: '+dataMonthly.text2_stroke_width+'px #'+dataMonthly.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text2._6:before { content: "แถว รับ"; position: absolute; -webkit-text-stroke: '+dataMonthly.text2_stroke_width+'px #'+dataMonthly.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        
                                                        '#text-group-'+(i+1)+' .text3._1:before { content: "'+(campaignTarget[i].target)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text3._2:before { content: "'+(campaignTarget[i].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text3._3:before { content: "'+(campaignTarget[i].reward_name)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text3._4:before { content: "'+(calcTargetValueBox(campaignTarget[i].target))+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-'+(i+1)+' .text3._5:before { content: "'+(calcTargetValueRow(campaignTarget[i].target))+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        
                                                    ].join('\n')
                                                }}>
                                                </style>
                                                <span className='text2 _1'>{
                                                    dataMonthly.text2_include_line_no ? 
                                                        (i+1)+'. ครบ'
                                                    :
                                                        'ครบ'
                                                }&nbsp;</span>
                                                {
                                                    campaignTargetUnit === 'ลัง' ? 
                                                    <React.Fragment>
                                                        <span className='text3 _4'>{ calcTargetValueBox(campaignTarget[i].target) }&nbsp;</span>
                                                        <span className='text2 _5'>ลัง&nbsp;</span>

                                                        {
                                                            calcTargetValueRow(campaignTarget[i].target) > 0 ?
                                                                <React.Fragment>
                                                                    <span className='text3 _5'>{ calcTargetValueRow(campaignTarget[i].target) }&nbsp;</span>
                                                                    <span className='text2 _6'>แถว&nbsp;รับ&nbsp;</span>
                                                                </React.Fragment>
                                                            : null
                                                        }
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <span className='text3 _1'>{ campaignTarget[i].target }&nbsp;</span>
                                                        <span className='text2 _2'>{ campaignTargetUnit } รับ&nbsp;</span>
                                                    </React.Fragment>
                                                }
                                                {
                                                    (campaignTarget[i].reward_type === 'sfdc') ? 
                                                        <React.Fragment>
                                                            <span className='text3 _2'>{ (campaignTarget[i].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)} &nbsp;</span>
                                                            <span className='text2 _4'>คะแนน</span> 
                                                        </React.Fragment>
                                                    : 
                                                        <span className='text3 _3'>
                                                            { campaignTarget[i].reward_name }
                                                        </span>
                                                }
                                                
                                            </p>
                                        </React.Fragment>
                                    );
                                })
                            :
                                <React.Fragment >
                                    <style dangerouslySetInnerHTML={{
                                        __html: [
                                            '.text1{', 
                                                'font-size: '+dataMonthly.text1_size+'rem;',
                                                'color: #'+dataMonthly.text1_color+';',
                                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                            '.text1:before {',
                                                'text-shadow: '+dataMonthly.text1_shadow_width+'px '+dataMonthly.text1_shadow_width+'px '+dataMonthly.text1_shadow_width+'px #'+dataMonthly.text1_shadow_color+';',
                                            '}',
                                            '.text3{', 
                                                'font-size: '+dataMonthly.text3_size+'rem;',
                                                'color: #'+dataMonthly.text3_color+';',
                                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                            '.text3:before {',
                                                'text-shadow: '+dataMonthly.text3_shadow_width+'px '+dataMonthly.text3_shadow_width+'px '+dataMonthly.text3_shadow_width+'px #'+dataMonthly.text3_shadow_color+';',
                                            '}',
                                            ].join('\n')
                                            }}>
                                    </style>
                                    <p id="text-group-0">
                                        {
                                            (campaignTargetUnit === 'ลัง') && !checkNumber(campaignTarget[0].target) ? 
                                            getBoxNRow1(campaignTarget[0].target)   
                                            :
                                            <React.Fragment>
                                                <style dangerouslySetInnerHTML={{
                                                    __html: [
                                                        '#text-group-0 .text1._1:before { content: "ให้ถึงเป้า"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-0 .text1._2:before { content: "'+(campaignTargetUnit)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text1_stroke_width+'px #'+dataMonthly.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                        '#text-group-0 .text3._1:before { content: "'+(campaignTarget[0].target)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text3_stroke_width+'px #'+dataMonthly.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    ].join('\n')
                                                }}>
                                                </style>
                                                <span className='text1 _1'>ให้ถึงเป้า</span>&nbsp;
                                                <span className='text3 _1'>{ campaignTarget[0].target }</span>&nbsp;
                                                <span className='text1 _2'>{campaignTargetUnit}</span> 
                                            </React.Fragment>
                                        }
                                        
                                    </p>
                                </React.Fragment>        
                        }
                    </div>
                    
                    <div className="App-bar" id="app-bar" ref={barRef}>
                        <div className="App-bar-stick">
                            <div className="round-stick">
                                <div className="bar-group bar-group">
                                    <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                </div>
                                {/* {
                                    
                                    userProgressMonth.map((item,i)=>{
                                        return(
                                            <div className={'bar-group bar-group-'+(i)} key={i}>
                                                <div id={'bar-stick-green-'+i} className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                                <div id={'bar-stick-yellow-'+i}  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                            </div>)
                                        }
                                    )
                                } */}
                            </div>
                        </div>
                        <img src={bar} className="App-bar-border" alt="bar" />
                        {
                            campaignTarget.map((item,i)=>{
                                return (
                                //     <div className="App-target-box" style={{left: targetPos * (i+1), width: appBar / 1.8, height: appBar / 1.8,}} key={i} >
                                //         <img src={target1000} className="App-target" alt="target-reached" />
                                //     </div>
                                    <div className="App-target-box" style={{left: targetPos * (i+1), width: appBar / 1.8, height: appBar / 1.8,}} key={i} >{
                                            (userProgress >= campaignTarget[i].target) ? 
                                                <img src={item.target_picture_reached} className="App-target" alt="target-reached" />
                                            :
                                                <img src={item.target_picture_not_reached} className="App-target" alt="target-not-reached" />   
                                        }
                                        
                                        {/* <img src={target1000} className="App-target" alt="target" /> */}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="App-status">
                        
                        <div className="status-content">
                            <img src={progressBullet} className="status-bullet" alt="progressBullet" />
                            {
                                (campaignTargetUnit === 'ลัง') && !checkNumber(userProgress) ? 
                                getBoxNRow2(userProgress)  
                                :
                                <React.Fragment>
                                    <style dangerouslySetInnerHTML={{
                                        __html: [
                                            '.text4{', 
                                                'font-size: '+dataMonthly.text4_size+'rem;',
                                                'color: #'+dataMonthly.text4_color+';',
                                                'text-shadow: '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px #'+dataMonthly.text4_shadow_color+';',
                                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                            '.text4:before {',
                                                'text-shadow: '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px '+dataMonthly.text4_shadow_width+'px #'+dataMonthly.text4_shadow_color+';',
                                            '}',
                                            '.text4._1:before { content: "'+(userProgress)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text4_stroke_width+'px #'+dataMonthly.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                            
                                            '.text5{', 
                                                'font-size: '+dataMonthly.text5_size+'rem;',
                                                'color: #'+dataMonthly.text5_color+';',
                                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                            '.text5:before {',
                                                'text-shadow: '+dataMonthly.text5_shadow_width+'px '+dataMonthly.text5_shadow_width+'px '+dataMonthly.text5_shadow_width+'px #'+dataMonthly.text5_shadow_color+';',
                                            '}',
                                            '.text5._1:before { content: "'+(campaignTargetUnit)+'"; position: absolute; -webkit-text-stroke: '+dataMonthly.text5_stroke_width+'px #'+dataMonthly.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                            
                                            
                                            ].join('\n')
                                        }}>
                                    </style>
                                    <div className="status-text">
                                        <span className="text4 _1">{userProgress}&nbsp;</span>
                                        <span className="text5 _1">{campaignTargetUnit}</span>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    {
                        (dataMonthly.text6_target_extra) ? // text6_target_extra === 1
                            <div className="App-text-extra">
                                <style dangerouslySetInnerHTML={{
                                    __html: [
                                        '.textx{', 
                                            'font-size: '+dataMonthly.text6_size+'rem;',
                                            'color: #'+dataMonthly.text6_color+';',
                                            'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                        ].join('\n')
                                    }}>
                                </style>
                                <span className="textx">
                                    {dataMonthly.text6_content1}&nbsp; 
                                    {dataMonthly.targetx.target}&nbsp;
                                    {dataMonthly.text6_target_unit}&nbsp;
                                    {
                                        (dataMonthly.targetx.reward_type === 'sfdc') ? 
                                            'รับ '+(dataMonthly.targetx.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)+' คะแนน ' 
                                        :
                                            'รับ '+dataMonthly.targetx.reward_name+' '
                                    }&nbsp;
                                    {dataMonthly.text6_content3}</span>
                            </div>
                        :  // text6_target_extra === 0
                            <div className="App-text-extra">
                                <style dangerouslySetInnerHTML={{
                                    __html: [
                                        '.textx{', 
                                            'font-size: '+dataMonthly.text6_size+'rem;',
                                            'color: #'+dataMonthly.text6_color+';',
                                            'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                        ].join('\n')
                                    }}>
                                </style>
                                <span className="textx">
                                    {dataMonthly.text6_content1} 
                                    {
                                        (dataMonthly.targetx.reward_type === 'sfdc') ? 
                                            'รับ '+(dataMonthly.targetx.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)+' คะแนน ' 
                                        :
                                            'รับ '+dataMonthly.targetx.reward_name+' '
                                    }
                                    {dataMonthly.text6_content3}
                                </span>
                            </div>
                    }

                    <div className="App-monlty-box">
                        {
                            campaignDurationFn()
                        }
                    </div>
                </div>
            </div>
        )

}

export default Monthly
