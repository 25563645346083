import React, { useEffect, useState, useLayoutEffect, useCallback } from 'react'
import moment from 'moment'

// import approved from '../assets/images/campaign1/bullet-green.png';
// import pending from '../assets/images/campaign1/bullet-yellow.png';


function MonthlyShort2(props) {
    // const [campaignTargetValue, setCampaignTargetValue] = useState(400.105) // campaignTarget.target
    const [campaignTargetValueBox, setCampaignTargetValueBox] = useState(0)
    const [campaignTargetValueRow, setCampaignTargetValueRow] = useState(0)
    // const [campaignTargetUnit, setCampaignTargetUnit] = useState("ลัง") // dataMonthlyshort.target_unit
    const [progressBox, setProgressBox] = useState(0)
    const [progressRow, setProgressRow] = useState(0)

    const { dataCampaign } = props;
    const dataMonthlyshort = dataCampaign.campaign.monthlyshort
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataMonthlyshort.target[0];
    let campaignTargetValue = campaignTarget.target // 16  ;
    const campaignTargetUnit = dataMonthlyshort.target_unit // "ลัง";  ;
    const userProgress = dataUser.progress // 16 ;

    // Images data
    const banner = dataMonthlyshort.background_picture;
    const updateTime = dataUser.updated_time;

    const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']
    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' '+dataTime)
    }

    const calcTargetValue = useCallback(() => {
        let int_part = Math.trunc(campaignTargetValue); // returns 3
        let float_part = Number((campaignTargetValue - int_part).toFixed(2)); 
        // console.log(int_part)
        // console.log(float_part)

        if(dataMonthlyshort.product_code_type === "non_ryo") {
            setCampaignTargetValueRow(Math.round(float_part * 50))
        } else if(dataMonthlyshort.product_code_type === "ryo") {
            setCampaignTargetValueRow(Math.round(float_part * 20))
        } else {
            setCampaignTargetValueRow(float_part)
        }

        setCampaignTargetValueBox(int_part)
        
        // return Number.isInteger(campaignTargetUnit) ? campaignTargetValue : campaignTargetValue;
    },[campaignTargetValue, dataMonthlyshort.product_code_type])

    const calcProgressValue = useCallback(()=>{
        let result = userProgress
        let int_part = Math.trunc(result); // returns 3
        let float_part = Number((result - int_part).toFixed(2)); 
        // console.log('remaining:',int_part)
        // console.log('remaining:',float_part)

        if(dataMonthlyshort.product_code_type === "non_ryo") {
            let lastResult = Math.round(float_part * 50)
            setProgressRow(lastResult > 0 ? lastResult : 0)
        } else {
            let lastResult = Math.round(float_part * 20)
            setProgressRow(lastResult > 0 ? lastResult : 0)
        }

        setProgressBox(int_part > 0 ? int_part : 0)
    },[dataMonthlyshort.product_code_type, userProgress])

    
    useEffect(() => {
        
        // console.log(dataCampaign)
        if(campaignTargetUnit === "ลัง") {
            calcTargetValue();
            calcProgressValue();
        }

    }, [campaignTarget.length, campaignTargetUnit, dataUser.checked, dataUser.pending, calcTargetValue, calcProgressValue, userProgress])

    
    // Check Resize
    useLayoutEffect(() => {
        return () => {};
    }, [])


    return (
        <div className="App-monthlyshort-V2">
            <header className="App-banner">
            {
                    banner ? 
                    <img src={banner} alt="banner" />
                    :
                    <div className="noimg">
                        No Image
                    </div>
                    
                }
            </header>
            <div className="App-content">
                <div className="App-content-box">
                    <p className="first-content">
                        <span className='normal texth'>{ dataMonthlyshort.text1_content }</span>
                        <br/>
                        <span className="normal-gray">เป้าหมาย</span>&nbsp;
                        {
                            campaignTargetUnit === "ลัง" ?
                                <React.Fragment>
                                    <span className='hilight'>{ campaignTargetValueBox }</span>&nbsp;
                                    <span className='normal-gray'>{ campaignTargetUnit }</span>&nbsp;
                                    {
                                        campaignTargetValueRow > 0 ?
                                            <React.Fragment>
                                                <span className='hilight'>{ campaignTargetValueRow }</span>&nbsp;
                                                <span className='normal-gray'>แถว</span>
                                            </React.Fragment>
                                        : null
                                    }
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <span className='hilight'>{ campaignTargetValue }</span>&nbsp;
                                    <span className='normal-gray'>{ campaignTargetUnit }</span>
                                </React.Fragment>
                        } &nbsp;
                        <br/>
                        <span className='normal-gray'>ปิดเป้าได้ รับเลย</span>&nbsp;
                        {
                            (campaignTarget.reward_type === 'sfdc') ? 
                            <React.Fragment>
                                <span className='hilight'>{ (campaignTarget.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0)} </span>
                                <span className='normal-gray'>คะแนน</span> 
                            </React.Fragment>
                            : 
                            <span className='hilight'>
                                { campaignTarget.reward_name }
                            </span>
                        }
                        <br/>
                    </p>
                    <p className="second-content">
                        <span className='normal-gray'>คุณทำได้</span>
                        <br/>
                        {
                            campaignTargetUnit === "ลัง" ?
                                <React.Fragment>
                                    <span className='hilight'>{ progressBox }</span>&nbsp;
                                    <span className='normal-gray hilight-unit'>{ campaignTargetUnit }</span>&nbsp;
                                    {
                                        progressRow > 0 ?
                                            <React.Fragment>
                                                <span className='hilight'>{ progressRow }</span>&nbsp;
                                                <span className='normal-gray hilight-unit'>แถว</span>
                                            </React.Fragment>
                                        : null
                                    }
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <span className='hilight'>{ userProgress }</span>&nbsp;
                                    <span className='normal-gray hilight-unit'>{ campaignTargetUnit }</span>
                                </React.Fragment>
                        } &nbsp;
                    </p>
                </div>
            </div>
            <div className="App-status">
                <div className="status-content">
                    <span className="text3 _4">ข้อมูล ณ วันที่ {calcUpdatedDate(updateTime)} น.</span>
                </div>
            </div>
        </div>
    )

}

export default MonthlyShort2
