import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Legend, } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// import progressBullet from '../assets/images/campaign1/bullet-green.png';

ChartJS.register(ArcElement, Legend);


function Monthly2(props) {
    const barRef = useRef(null);
    const contentRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    // const [appBar, setAppBar] = useState(0)
    const [heightContent, setHeightContent] = useState(0);
 
    const { dataCampaign } = props;
    const dataMonthly = dataCampaign.campaign.monthly
    const start_date = dataCampaign.campaign.start_date
    const end_date = dataCampaign.campaign.end_date
    const product_code_type = dataCampaign.campaign.monthly.product_code_type // 'non_ryo' 
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataMonthly.target;
    const campaignTargetUnit = dataMonthly.target_unit;
    
    const userProgressMonth = dataUser.progress_month;
    const userProgress = dataUser.progress;

    // Images data
    // const bar = dataMonthly.progressbar_picture;
    const banner = dataMonthly.background_picture;
    const updateTime = dataUser.updated_time;

    const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']
    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' '+dataTime)
    }

    // Campaign duration
    let start_obj = moment(start_date); 
    let end_obj = moment(end_date)
    let campaignDuration = (end_obj.year()-start_obj.year())*12 + (end_obj.month()-start_obj.month()) + 1;

    const campaignDurationFn = () => {
        let campaignDurationArr = [];
        for(let i=0; i<campaignDuration; i++) {
            campaignDurationArr.push(
                <React.Fragment key={i} >
                    <div className={`box _${i}`}>
                        {/* <p className="tag-month normal-gray">เดือน</p> */}
                        <p className="tag-month normal-gray">{(i===0) ? dataMonthly.text7_month1 : (i===1) ? dataMonthly.text7_month2 : dataMonthly.text7_month3}</p>
                        <p className="tag-youcan normal-gray">คุณทำได้</p>
                        {
                            (campaignTargetUnit === 'ลัง') && !checkNumber(userProgressMonth[i]) ? 
                                // userProgressMonth[i] === null ? <React.Fragment><p><span className="text8">0 </span><span className="text7">{dataMonthly.target_unit}</span></p></React.Fragment> 
                                
                                !!userProgressMonth[i] ? 
                                    getBoxNRow3(userProgressMonth[i]) 
                                :
                                <React.Fragment>
                                    <p className="tag-result">
                                        <span className="hilight">0 </span>
                                        <span className="normal">{dataMonthly.target_unit}</span>
                                    </p>
                                </React.Fragment> 
                            :
                            <p className="tag-result"><span className="hilight">{userProgressMonth[i] === null ? 0 : userProgressMonth[i]}</span> <span className="normal">{dataMonthly.target_unit}</span></p>
                        }
                    </div>
                </React.Fragment>)
        }
        return campaignDurationArr;
    }

    const calcTargetValueBox = (val) => {
        let int_part = Math.trunc(val); // returns 3
        // console.log('target box',int_part)
        let result = int_part;
        
        return result;
    }

    const calcTargetValueRow = (val) => {
        let int_part = Math.trunc(val); // returns 3
        let float_part = Number((val - int_part).toFixed(2)); 
        // console.log('target box',int_part)
        // console.log('target row',int_part)
        // console.log('product_code_type',product_code_type)
        let result = 0;

        if(product_code_type === "non_ryo") {
            result = Math.round(float_part * 50)
        } else if(product_code_type === "ryo") {
            result = Math.round(float_part * 20)
        } else {
            result = float_part
        }

        // switch (product_code_type) {
        //     case "non_ryo":
        //         result = float_part * 50
        //         break;
        
        //     case "ryo":
        //         result = float_part * 20
        //         break;
            
        //     default:
        //         result = float_part
        //         break;
        // }

        return result;
    }

    const checkTargetPos = useCallback(() => {
        // console.log('check single & multi:',campaignTarget.length)
        // console.log('check position')
        const barWidth = (campaignTarget.length > 1) ? barRef.current.clientWidth : 0;
        const targetLength = campaignTarget.length;
        const targetPosition = Math.floor(barWidth / targetLength);

        setTargetPos(targetPosition);
    },[campaignTarget.length]);

    const calcBarPos = useCallback(() => {
        // let userProgressMonthAll = userProgressMonth.reduce(function(acc, val) { return acc + val; }, 0)
        const pb_length = (campaignTarget.length > 1) ? barRef.current.clientWidth : 0;
        const num_target = campaignTarget.length;
        let sec_length =  pb_length/num_target;
        let progress = userProgress;
        let paint_length = 0;

        for(let ind = 0; ind < num_target; ind++) {
            
            let campaignTargetCurrent = campaignTarget[ind].target;
            let campaignTargetPrev = (campaignTarget[ind-1] !== undefined ? campaignTarget[ind-1].target : 0);

            if( progress > campaignTargetCurrent) {
                // Paint full bar : green
                setBarGreen(Math.abs((ind+1)*sec_length));
            } else { 
                // Paint bar : green
                paint_length = (progress - campaignTargetPrev) * sec_length / (campaignTargetCurrent - campaignTargetPrev);
                setBarGreen(Math.abs((ind*sec_length) + paint_length));
                
                break;
            } 
        }
        
    },[campaignTarget, userProgress])

    // Check Number Float
    const checkNumber = (n) => {
        return n % 1 === 0
    }

    // Get target for box & row
    const getBoxNRow1 = (val) => {
        let getBox = parseInt(val) 
        // let getDigit = val.toString()
        let getRow = (Math.round(val*1000)%1000)/20;
        // let getRow = Number(getDigit.substring(getDigit.length - 3)) * 50
        // console.log(getBox,Number(getDigit.substring(getDigit.length - 3)), getRow)
        
        return (
            <React.Fragment>
                <span className='normal'>เป้าหมายของคุณ คือ</span>&nbsp;
                <span className='hilight-orange'>{ getBox }</span>&nbsp;
                <span className='normal'>ลัง</span>&nbsp;

                {
                    getRow > 0 ?
                        <React.Fragment>
                        <span className='hilight-orange'>{ getRow } </span>&nbsp;
                        <span className='normal'>แถว</span> 
                        </React.Fragment>
                    : null
                }
            </React.Fragment>
        )
    }

    const getBoxNRow3 = (val) => {
        // let getBox = parseInt(val)
        // let getDigit = val.toString()
        // let getRow = (Math.round(val*1000)%1000)/20;
        // let getRow = Number(getDigit.substring(getDigit.length - 3)) * 50
        // console.log(getBox,Number(getDigit.substring(getDigit.length - 3)), getRow)
        
        return (
            <React.Fragment>
                <p className="tag-result">
                    {/* <span className="text8">{getBox}</span> &nbsp; */}
                    <span className="hilight box-unit">{calcTargetValueBox(val)}</span> &nbsp;
                    <span className="normal">ลัง</span>&nbsp;
                    {/* <span className="text8">{getRow}</span> &nbsp; */}
                    {
                        calcTargetValueRow(val) > 0 ?
                            <React.Fragment>
                                <br/>
                                <span className="hilight row-unit">{calcTargetValueRow(val)}</span> &nbsp;
                                <span className="normal">แถว</span>
                            </React.Fragment>
                        : null
                    }
                </p>
                    
            </React.Fragment>
        )
    }
    
    useEffect(() => {
        // setAppBar((campaignTarget.length > 1) ? barRef.current.clientWidth : 0)
        
        // setIsLoaded(true)
        checkTargetPos();
        // console.log(dataCampaign)
        calcBarPos();
        // console.log(contentRef.current.childNodes[1].clientHeight)
        // setHeightContent(contentRef.current.childNodes[1].clientHeight)
        setHeightContent(32);  // 32 is 16px * 2

        // console.log(window.innerHeight)

    }, [barGreen, calcBarPos, campaignTarget.length, checkTargetPos, dataUser.checked, dataUser.pending, userProgressMonth])
    

    
    // Check Resize
    useLayoutEffect(() => {
        window.addEventListener('resize', checkTargetPos );
        window.addEventListener('resize', calcBarPos);
        checkTargetPos();
        calcBarPos();
        return () => {window.removeEventListener('resize', checkTargetPos); window.removeEventListener('resize', checkTargetPos);};
    }, [calcBarPos, checkTargetPos])


    const doughnutChart = () => {
        const reachedColor = '#199F98'
        const unreachedColor = '#D6D8E3'

        let reachedVal = !!dataUser.progress ? dataUser.progress : 0
        let unreachedVal = ((!!campaignTarget[0].target ? campaignTarget[0].target : 0) - reachedVal < 0) ? 0 : (!!campaignTarget[0].target ? campaignTarget[0].target : 0) - reachedVal

        const data = {
            datasets: [
                {
                    data: [reachedVal, unreachedVal],
                    backgroundColor: [
                        reachedColor,
                        unreachedColor,
                    ],
                    borderWidth: 0,
                },
            ],
        };

        const options = {
            animation: {
                duration: 0
            }
        }

        return (
            <div className="doughnutBox" style={{width: window.innerWidth/2.5+'px', height: window.innerWidth/2.5+'px'}}>
                <div className="doughnutDetailBox" style={{width: window.innerWidth/3.0+'px', height: window.innerWidth/3.0+'px'}}>
                    {/* {
                        unreachedVal ?
                            <React.Fragment>
                                <p>ยอดรวม </p>
                                <p><span>{ reachedVal }</span>/{campaignTarget[0].target}</p>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <p>ยอดรวม </p>
                                <p><span>{ campaignTarget[0].target }</span></p>
                            </React.Fragment>

                    } */}
                    <p>ยอดรวม </p>
                    <p>
                        <span>{ reachedVal }</span>/{campaignTarget[0].target}
                    </p>
                    <p>{dataMonthly.target_unit}</p>
                </div>
                <Doughnut data={data} options={options} />
            </div>
        )
    }

    return (
        <div className="App-monthly-V2">
            <header className="App-banner">
            {
                    banner ? 
                    <img src={banner} alt="banner" />
                    :
                    <div className="noimg">
                        No Image
                    </div>
                    
                }
            </header>
            <div className="App-content" style={{minHeight: heightContent * 5+'px'}}>
                {
                    campaignTarget.length > 1 ?
                        // Multiple Target
                        <React.Fragment>
                            <p className="header-target">เพียงสะสมครบตามเป้าหมายย่อย</p>
                            {
                                campaignTarget.map((item,i)=>{
                                    return (
                                        <React.Fragment key={i}>
                                            <p key={i} id={`text-group-${i+1}`} ref={contentRef}>
                                                <span className='normal-gray'>{
                                                    dataMonthly.text2_include_line_no ? 
                                                        (i+1)+'. ครบ'
                                                    :
                                                        'ครบ'
                                                }&nbsp;</span>
                                                {
                                                    campaignTargetUnit === 'ลัง' ? 
                                                    <React.Fragment>
                                                        <span className='hilight'>{ calcTargetValueBox(campaignTarget[i].target) }&nbsp;</span>
                                                        <span className='normal-gray'>ลัง&nbsp;</span>

                                                        {
                                                            calcTargetValueRow(campaignTarget[i].target) > 0 ?
                                                                <React.Fragment>
                                                                    <span className='hilight'>{ calcTargetValueRow(campaignTarget[i].target) }&nbsp;</span>
                                                                    <span className='normal-gray'>แถว&nbsp;รับ&nbsp;</span>
                                                                </React.Fragment>
                                                            : null
                                                        }
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <span className='hilight'>{ campaignTarget[i].target }&nbsp;</span>
                                                        <span className='normal-gray'>{ campaignTargetUnit } รับ&nbsp;</span>
                                                    </React.Fragment>
                                                }
                                                {
                                                    (campaignTarget[i].reward_type === 'sfdc') ? 
                                                        <React.Fragment>
                                                            <span className='hilight'>{ (campaignTarget[i].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)} &nbsp;</span>
                                                            <span className='normal-gray'>คะแนน</span> 
                                                        </React.Fragment>
                                                    : 
                                                        <span className='hilight'>
                                                            { campaignTarget[i].reward_name }
                                                        </span>
                                                }
                                                
                                            </p>
                                        </React.Fragment>
                                    );
                                })
                            }
                            <br/>
                            <div className="App-total">
                                <p className="header-target">ยอดสะสม</p>
                                <p>
                                {
                                    campaignTargetUnit === 'ลัง' ? 
                                    <React.Fragment>
                                        <span className='hilight header-target'>{ calcTargetValueBox(userProgress) }&nbsp;</span>
                                        <span className='normal header-target'>ลัง&nbsp;</span>

                                        {
                                            calcTargetValueRow(userProgress) > 0 ?
                                                <React.Fragment>
                                                    <span className='hilight header-target'>{ calcTargetValueRow(userProgress) }&nbsp;</span>
                                                    <span className='normal header-target'>แถว</span>
                                                </React.Fragment>
                                            : null
                                        }
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        <span className='hilight header-target'>{ userProgress }&nbsp;</span>
                                        <span className='normal header-target'>{ campaignTargetUnit } </span>
                                    </React.Fragment>
                                }
                                </p>
                            </div>

                            <div className="App-bar-section">
                                <div className="App-bar" id="app-bar" ref={barRef}>
                                    <div className="App-bar-border">
                                        <p>เป้าหมาย</p>
                                    </div>
                                    <div className="App-bar-stick">
                                        <div className="round-stick">
                                            {/* <div className="bar-group bar-group"> */}
                                                <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                            {/* </div> */}
                                            {/* {
                                                
                                                userProgressMonth.map((item,i)=>{
                                                    return(
                                                        <div className={'bar-group bar-group-'+(i)} key={i}>
                                                            <div id={'bar-stick-green-'+i} className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                                            <div id={'bar-stick-yellow-'+i}  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                                        </div>)
                                                    }
                                                )
                                            } */}
                                        </div>
                                    </div>
                                    {/* <img src={bar} className="App-bar-border" alt="bar" /> */}
                                    {
                                        campaignTarget.map((item,i)=>{
                                            return (
                                            //     <div className="App-target-box" style={{left: targetPos * (i+1), width: appBar / 1.8, height: appBar / 1.8,}} key={i} >
                                            //         <img src={target1000} className="App-target" alt="target-reached" />
                                            //     </div>
                                                <div className="App-target-box" style={{left: targetPos * (i+1), width: 20, height: 20,}} key={i} >{
                                                        (userProgress >= campaignTarget[i].target) ? 
                                                            <div className="target-reached">
                                                                <p>{campaignTarget[i].target}</p>
                                                            </div>
                                                            // <img src={item.target_picture_reached} className="App-target" alt="target-reached" />
                                                        :
                                                            <div className="target-not-reached">
                                                                <p>{campaignTarget[i].target}</p>
                                                            </div>
                                                            // <img src={item.target_picture_not_reached} className="App-target" alt="target-not-reached" />   
                                                    }
                                                    
                                                    {/* <img src={target1000} className="App-target" alt="target" /> */}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    :
                        // Single Target
                        <React.Fragment >
                            <p className="header-target" id="text-group-0">
                                {
                                    (campaignTargetUnit === 'ลัง') && !checkNumber(campaignTarget[0].target) ? 
                                    getBoxNRow1(campaignTarget[0].target)   
                                    :
                                    <React.Fragment>
                                        <span className='normal'>เป้าหมายของคุณ คือ</span>&nbsp;
                                        <span className='hilight-orange'>{ campaignTarget[0].target }</span>&nbsp;
                                        <span className='normal'>{campaignTargetUnit}</span> 
                                    </React.Fragment>
                                }
                            </p>
                            <div className="App-detail">
                                <div className="_left">
                                    <p className="header">ยอดสะสมรายเดือน</p>
                                    {
                                        campaignDurationFn()
                                    }
                                </div>
                                <div className="_right">
                                    {
                                        doughnutChart()
                                    }
                                </div>  
                            </div>
                        </React.Fragment>        
                }
            </div>

            {/* <div className="App-status">
                
                <div className="status-content">
                    <img src={progressBullet} className="status-bullet" alt="progressBullet" />
                    {
                        (campaignTargetUnit === 'ลัง') && !checkNumber(userProgress) ? 
                        getBoxNRow2(userProgress)  
                        :
                        <React.Fragment>
                            <div className="status-text">
                                <span className="hilight">{userProgress}&nbsp;</span>
                                <span className="normal">{campaignTargetUnit}</span>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div> */}

            {
                // Text Extra
                (dataMonthly.text6_target_extra) ? // text6_target_extra === 1
                    <div className="App-text-extra">
                        <span className="textx">
                            {dataMonthly.text6_content1}&nbsp; 
                            <span className="hilight">
                                {
                                    campaignTargetUnit === 'ลัง' ? 
                                    <React.Fragment>
                                        <span className='hilight'>{ calcTargetValueBox(dataMonthly.targetx.target) }&nbsp;</span>
                                        <span className='hilight'>ลัง&nbsp;</span>

                                        {
                                            calcTargetValueRow(dataMonthly.targetx.target) > 0 ?
                                                <React.Fragment>
                                                    <span className='hilight'>{ calcTargetValueRow(dataMonthly.targetx.target) }&nbsp;</span>
                                                    <span className='hilight'>แถว&nbsp;</span>
                                                </React.Fragment>
                                            : null
                                        }
                                    </React.Fragment>
                                : 
                                    <React.Fragment>
                                        <span className='hilight'>
                                            {dataMonthly.targetx.target}&nbsp;
                                            {dataMonthly.text6_target_unit}&nbsp;
                                        </span>
                                    </React.Fragment>
                                }
                                
                            </span>
                            รับ&nbsp;
                            {
                                (dataMonthly.targetx.reward_type === 'sfdc') ? 
                                    <span className="hilight">{(dataMonthly.targetx.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)}&nbsp;คะแนน&nbsp;</span>
                                :
                                    <span className="hilight">{dataMonthly.targetx.reward_name}&nbsp;</span>
                            }&nbsp;
                            {dataMonthly.text6_content3}</span>
                    </div>
                :  // text6_target_extra === 0
                    <div className="App-text-extra">
                        <span className="textx">
                            {dataMonthly.text6_content1} 
                            รับ&nbsp;
                            {
                                (dataMonthly.targetx.reward_type === 'sfdc') ? 
                                    <span className="hilight">{(dataMonthly.targetx.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)}&nbsp;คะแนน&nbsp;</span>
                                :
                                    <span className="hilight">{dataMonthly.targetx.reward_name}&nbsp;</span>
                            }
                            {dataMonthly.text6_content3}
                        </span>
                    </div>
            }

            {
                campaignTarget.length > 1 ?
                    <div className="App-monlty-box">
                        {
                            campaignDurationFn()
                        }
                    </div>
                : null
            }
            <div className="App-status">
                <div className="status-content">
                    <span className="text3 _4">ข้อมูล ณ วันที่ {calcUpdatedDate(updateTime)} น.</span>
                </div>
            </div>
        </div>
    )

}

export default Monthly2
