import React from 'react'

function Leader(props) {
    console.log(process.env.REACT_APP_API_HOST)
    const { dataCampaign } = props;
    const dataLeaderboard = dataCampaign.campaign.leaderboard
    const dataUser = dataCampaign.user;
    const dataTop = dataCampaign.top;
    // console.log(dataTop)

    // User data
    const userRank = dataUser.rank;
    const userName = dataUser.name;
    const userProgress = dataUser.progress;
    const userRewardText = dataUser.reward_text;

    // const userProgress = 20;

    // Target data
    // const target = dataLeaderboard.target;

    // Images data
    const background = dataLeaderboard.background_picture;
    const header = dataLeaderboard.headline_picture;

    const getTableRow = () => {
        let resultArr = [];
        for (let i = 0; i < dataTop.length; i++) {
            let item = dataTop[i];
            if(i === 0) {
                resultArr.push(
                    <tr key={i}>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row1_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.rank}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row1_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.name}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row1_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.progress}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row1_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.reward_text}</td>
                    </tr>
                )
            } else if(i === 1) {
                resultArr.push(
                    <tr key={i}>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row2_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.rank}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row2_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.name}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row2_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.progress}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row2_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.reward_text}</td>
                    </tr>
                )
            } else if(i === 2) {
                resultArr.push(
                    <tr key={i}>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row3_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.rank}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row3_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.name}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row3_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.progress}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.row3_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.reward_text}</td>
                    </tr>
                )
            } else {
                resultArr.push(
                    <tr key={i}>
                        <td style={{backgroundColor: '#'+dataLeaderboard.normal_row_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.rank}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.normal_row_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.name}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.normal_row_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.progress}</td>
                        <td style={{backgroundColor: '#'+dataLeaderboard.normal_row_color, borderColor: '#'+dataLeaderboard.cell_border_color,color: '#'+dataLeaderboard.cell_text_color}}>{item.reward_text}</td>
                    </tr>
                )
            }
        }

        return resultArr;
    }

    return (
        <div className="App-leaderboard">
            <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                <header className="App-header">
                    <img src={header} className="App-logo" alt="logo" />
                </header>
                <div className="App-content">
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            '.text1{', 
                                'font-size: '+dataLeaderboard.text1_size+'rem;',
                                'color: #'+dataLeaderboard.text1_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text1:before {',
                                'text-shadow: '+dataLeaderboard.text1_shadow_width+'px '+dataLeaderboard.text1_shadow_width+'px '+dataLeaderboard.text1_shadow_width+'px #'+dataLeaderboard.text1_shadow_color+';',
                            '}',
                            '.text2{', 
                                'font-size: '+dataLeaderboard.text2_size+'rem;',
                                'color: #'+dataLeaderboard.text2_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text2:before {',
                                'text-shadow: '+dataLeaderboard.text2_shadow_width+'px '+dataLeaderboard.text2_shadow_width+'px '+dataLeaderboard.text2_shadow_width+'px #'+dataLeaderboard.text2_shadow_color+';',
                            '}',
                            '.text3{', 
                                'font-size: '+dataLeaderboard.text3_size+'rem;',
                                'color: #'+dataLeaderboard.text3_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text3:before {',
                                'text-shadow: '+dataLeaderboard.text3_shadow_width+'px '+dataLeaderboard.text3_shadow_width+'px '+dataLeaderboard.text3_shadow_width+'px #'+dataLeaderboard.text3_shadow_color+';',
                            '}',
                            '.text4{', 
                                'font-size: '+dataLeaderboard.text4_size+'rem;',
                                'color: #'+dataLeaderboard.text4_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text4:before {',
                                'text-shadow: '+dataLeaderboard.text4_shadow_width+'px '+dataLeaderboard.text4_shadow_width+'px '+dataLeaderboard.text4_shadow_width+'px #'+dataLeaderboard.text4_shadow_color+';',
                            '}',
                            '.text5{', 
                                'font-size: '+dataLeaderboard.text5_size+'rem;',
                                'color: #'+dataLeaderboard.text5_color+';',
                                'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                            '.text5:before {',
                                'text-shadow: '+dataLeaderboard.text5_shadow_width+'px '+dataLeaderboard.text5_shadow_width+'px '+dataLeaderboard.text5_shadow_width+'px #'+dataLeaderboard.text5_shadow_color+';',
                            '}',
                            ].join('\n')
                        }}>
                    </style>
                    <p className="App-content-text _12">
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text1._1:before { content: "'+dataLeaderboard.text1_context+'"; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text1_stroke_width+'px #'+dataLeaderboard.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text2._1:before { content: "'+dataLeaderboard.text2_context+'"; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text2_stroke_width+'px #'+dataLeaderboard.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                
                            ].join('\n')
                            }}>
                        </style>
                        <span className="text1 _1">{dataLeaderboard.text1_context}</span><br/>
                        <span className="text2 _1">{dataLeaderboard.text2_context}</span><br/>
                    </p>
                    <p className="App-content-text _3">
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text3._1:before { content: "'+dataLeaderboard.text3_context+'"; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text3_stroke_width+'px #'+dataLeaderboard.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                ].join('\n')
                            }}>
                        </style>
                        <span className="text3 _1">{dataLeaderboard.text3_context}</span><br/>
                    </p>


                    {
                        dataLeaderboard.text4_lines.map((item,i)=>{
                            return (
                                <p key={i} id={`text-group-${i+1}`}>
                                    <style dangerouslySetInnerHTML={{
                                        __html: [
                                            '#text-group-'+(i+1)+' .text4._1:before { content: "'+item.order+' "; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text4_stroke_width+'px #'+dataLeaderboard.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                            '#text-group-'+(i+1)+' .text4._2:before { content: "'+item.reward+'"; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text4_stroke_width+'px #'+dataLeaderboard.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                            '#text-group-'+(i+1)+' .text5._1:before { content: " ได้รับ "; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text5_stroke_width+'px #'+dataLeaderboard.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                            '#text-group-'+(i+1)+' .text5._2:before { content: " ต่อร้าน "; position: absolute; -webkit-text-stroke: '+dataLeaderboard.text5_stroke_width+'px #'+dataLeaderboard.text5_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                            
                                        ].join('\n')
                                        }}>
                                    </style>
                                    <span className="text4 _1">{item.order}</span>&nbsp;<span className="text5 _1">ได้รับ</span>&nbsp;<span className="text4 _2">{item.reward}</span>&nbsp;<span className="text5 _2">ต่อร้าน</span>
                                </p>
                            );
                        })
                    }
                </div>
                <div className="App-table">
                    <div className="App-table-border">
                        <table className="App-table-self">
                            <thead>
                                <tr>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.self_cell_color, borderColor: '#'+dataLeaderboard.self_border_color,color: '#'+dataLeaderboard.self_text_color}}>{userRank}</th>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.self_cell_color, borderColor: '#'+dataLeaderboard.self_border_color,color: '#'+dataLeaderboard.self_text_color}}>{userName}</th>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.self_cell_color, borderColor: '#'+dataLeaderboard.self_border_color,color: '#'+dataLeaderboard.self_text_color}}>{userProgress}</th>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.self_cell_color, borderColor: '#'+dataLeaderboard.self_border_color,color: '#'+dataLeaderboard.self_text_color}}>{userRewardText}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div className="App-table-border" style={{maxHeight: (window.innerHeight/3)+'px', overflow: 'auto'}}>
                        <table className="App-table-top">
                            <thead>
                                <tr>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.top_row_color, borderColor: '#'+dataLeaderboard.cell_border_color, color: '#'+dataLeaderboard.top_row_text_color}}>ลำดับ</th>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.top_row_color, borderColor: '#'+dataLeaderboard.cell_border_color, color: '#'+dataLeaderboard.top_row_text_color}}>ร้านค้า</th>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.top_row_color, borderColor: '#'+dataLeaderboard.cell_border_color, color: '#'+dataLeaderboard.top_row_text_color}}>{dataLeaderboard.metric_content}</th>
                                    <th style={{backgroundColor: '#'+dataLeaderboard.top_row_color, borderColor: '#'+dataLeaderboard.cell_border_color, color: '#'+dataLeaderboard.top_row_text_color}}>รางวัล</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getTableRow()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leader
