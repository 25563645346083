import React from 'react'

function MainError(props) {
    return (
        <div className="App-main">
            <p>ไม่มี campaign นี้</p>
        </div>
    )
}

export default MainError
