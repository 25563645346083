import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'

// import background from '../assets/images/campaign1/bg.jpg';
// import header from '../assets/images/campaign1/header.png';
// import bar from '../assets/images/campaign1/bar.png';
// import target from '../assets/images/campaign1/target.png';
// import target1000 from '../assets/images/campaign1/04-2_Target.png';
import approved from '../assets/images/campaign1/bullet-green.png';
import pending from '../assets/images/campaign1/bullet-yellow.png';


function Barcode(props) {
    const barRef = useRef(null);
    // const [isLoaded, setIsLoaded] = useState(false)
    const [targetPos, setTargetPos] = useState([]);
    const [barGreen, setBarGreen] = useState(0)
    const [barYellow, setBarYellow] = useState(0)
    const [appBar, setAppBar] = useState(0)

    const { dataCampaign } = props;
    const dataBarcode = dataCampaign.campaign.barcode
    const dataUser = dataCampaign.user;

    // Target data
    const campaignTarget = dataBarcode.target;
    const userTarget = dataUser.target;
    // const userTarget = [2, 3, 4, 10, 12];
    // const userChecked = dataUser.checked;
    // const userPending = dataUser.pending;

    // Images data
    const background = dataBarcode.background_picture;
    const header = dataBarcode.headline_picture;
    const bar = dataBarcode.progressbar_picture;

    // User data
    // const barGreen = dataUser;


    // const textStyle = (numTextPosition) => {
    //     const textPosition = numTextPosition;

    //     // const xxxx = textPosition;
    //     const txtColor = '#'+dataBarcode[textPosition+'_color'];
    //     const txtSize = dataBarcode[textPosition+'_size']+'rem';
    //     const txtShadowWidth = dataBarcode[textPosition+'_shadow_width']+'px';
    //     const txtShadowColor = '#'+dataBarcode[textPosition+'_shadow_color'];
    //     const txtStrokeWidth = dataBarcode[textPosition+'_stroke_width']+'px';
    //     const txtStrokeColor = '#'+dataBarcode[textPosition+'_stroke_color'];

    //     const objStyle = {
    //         color: txtColor,
    //         textShadow: txtShadowWidth+' '+txtShadowWidth+' '+txtShadowWidth+' '+txtShadowColor,
    //         fontSize: txtSize,
    //         WebkitTextStroke: txtStrokeWidth+' '+txtStrokeColor,
    //     }
    //     return objStyle;
    // }




    const checkTargetPos = useCallback(() => {
        // console.log('check position')
        const barWidth = barRef.current.clientWidth;
        const targetLength = campaignTarget.length;
        const targetPosition = Math.floor(barWidth / targetLength);

        setTargetPos(targetPosition);
    },[campaignTarget.length]);

    const calcBarPos = useCallback(() => {
        // let userTargetAll = userTarget.reduce(function(acc, val) { return acc + val; }, 0)
        const pb_length = barRef.current.clientWidth;
        const num_target = userTarget.length;
        let sec_length =  pb_length/num_target;
        let approved = dataUser.checked;
        let unapproved = dataUser.pending;
        // let approved = 5;
        // let unapproved = 5;
        
        let paint_length = 0;
        let color = "green";
        for(let ind = 0; ind < num_target; ind++) {
            // console.warn('userTarget',userTarget[ind])
            let userTargetPrev = (userTarget[ind-1] ? userTarget[ind-1] : 0);
            let userTargetNext = (userTarget[ind+1] ? userTarget[ind+1] : 0);
            let userTargetCurrent = userTarget[ind];

            if( approved > userTarget[ind]) {
                // Paint full bar : green
                setBarGreen(Math.abs((ind+1)*sec_length));
                // console.warn('>>>>>>>> full <<<<<<<< ROUND:',ind)
            } else { 
                // console.warn('>>>>>>>> half <<<<<<<< ROUND:',ind)
                // Paint bar : green
                paint_length = ((approved - userTargetPrev) * sec_length / (userTargetCurrent - userTargetPrev));
                setBarGreen(Math.abs((ind*sec_length) + paint_length));

                if(color === 'yellow') {
                    break;
                } else {
                    color = 'yellow';

                    approved += unapproved  ;  // บวกค่าที่รอตรวจเข้าไปกับค่าที่ตรวจแล้ว 

                    let barYellowCurrent = 0;
                    let barYellowNext = 0;
                    let unapprovedBalance = unapproved - (approved - userTargetCurrent );
// console.log('approved ', approved)
// console.log('userTargetPrev ', userTargetPrev)
// console.log('userTargetCurrent ', userTargetCurrent)
// console.log('userTargetNext ', userTargetNext)
// console.log('unapprovedBalance = ', unapprovedBalance)
                    if(approved > userTargetCurrent) {
                        barYellowCurrent = Math.abs(unapprovedBalance) * Math.abs(sec_length / (userTargetCurrent - userTargetPrev));
                        barYellowNext =  Math.abs(approved - (userTargetCurrent ))  * Math.abs(sec_length / (userTargetNext - userTargetCurrent));
                        // paint_length = barYellowNext

// console.log('_barYellowNext ', barYellowNext)
// console.log('_barYellowCurrent',barYellowCurrent)
                    } else {
                        barYellowCurrent = Math.abs(unapproved) * (sec_length / (userTargetCurrent - userTargetPrev));
                        
                        // paint_length = barYellowCurrent

// console.log('*barYellowNext ', barYellowNext)
// console.log('*barYellowCurrent',barYellowCurrent)
                    }

                    paint_length = barYellowCurrent + barYellowNext
                    setBarYellow(paint_length)
                }

                
                break;
            } 
        }
        
        // console.log('barWidth', pb_length,'px -------------')
        // console.log('barGreen', barGreen,'px')
        // console.log('barYellow', barYellow,'px')
    },[dataUser.checked, dataUser.pending, userTarget])
    
    useEffect(() => {
        // console.log(barRef)
        // console.log(barRef.current.clientWidth)
        setAppBar(barRef.current.clientWidth)
        
        // setIsLoaded(true)
        // console.log('barRef : ',barRef.current.clientWidth);
        checkTargetPos();
        // console.log(dataCampaign)
        calcBarPos();
    }, [barGreen, barYellow, calcBarPos, campaignTarget.length, checkTargetPos, dataUser.checked, dataUser.pending, userTarget])

    
    // Check Resize
    useLayoutEffect(() => {
        window.addEventListener('resize', checkTargetPos );
        window.addEventListener('resize', calcBarPos);
        checkTargetPos();
        calcBarPos();
        return () => {window.removeEventListener('resize', checkTargetPos); window.removeEventListener('resize', checkTargetPos);};
    }, [calcBarPos, checkTargetPos])


    if(campaignTarget.length === userTarget.length) {
        return (
            <div>
                <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                    <header className="App-header">
                        <img src={header} className="App-logo" alt="logo" />
                    </header>
                    <div className="App-content">
                        {
                            campaignTarget.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <style dangerouslySetInnerHTML={{
                                            __html: [
                                                '.text1{', 
                                                    'font-size: '+dataBarcode.text1_size+'rem;',
                                                    'color: #'+dataBarcode.text1_color+';',
                                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                                '.text1:before {',
                                                    'text-shadow: '+dataBarcode.text1_shadow_width+'px '+dataBarcode.text1_shadow_width+'px '+dataBarcode.text1_shadow_width+'px #'+dataBarcode.text1_shadow_color+';',
                                                '}',
                                                '.text2{', 
                                                    'font-size: '+dataBarcode.text2_size+'rem;',
                                                    'color: #'+dataBarcode.text2_color+';',
                                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                                '.text2:before {',
                                                    'text-shadow: '+dataBarcode.text2_shadow_width+'px '+dataBarcode.text2_shadow_width+'px '+dataBarcode.text2_shadow_width+'px #'+dataBarcode.text2_shadow_color+';',
                                                '}',
                                                ].join('\n')
                                                }}>
                                        </style>
                                        <p key={i} id={`text-group-${i+1}`}>
                                            <style dangerouslySetInnerHTML={{
                                                __html: [
                                                    '#text-group-'+(i+1)+' .text1._1:before { content: "ครบ"; position: absolute; -webkit-text-stroke: '+dataBarcode.text1_stroke_width+'px #'+dataBarcode.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    '#text-group-'+(i+1)+' .text1._2:before { content: "แถว รับ"; position: absolute; -webkit-text-stroke: '+dataBarcode.text1_stroke_width+'px #'+dataBarcode.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    '#text-group-'+(i+1)+' .text1._3:before { content: "แถว รับ"; position: absolute; -webkit-text-stroke: '+dataBarcode.text1_stroke_width+'px #'+dataBarcode.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    '#text-group-'+(i+1)+' .text1._4:before { content: "คะแนน"; position: absolute; -webkit-text-stroke: '+dataBarcode.text1_stroke_width+'px #'+dataBarcode.text1_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    '#text-group-'+(i+1)+' .text2._1:before { content: "'+(userTarget[i])+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.text2_stroke_width+'px #'+dataBarcode.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    '#text-group-'+(i+1)+' .text2._2:before { content: "'+(campaignTarget[i].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.text2_stroke_width+'px #'+dataBarcode.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    '#text-group-'+(i+1)+' .text2._3:before { content: "'+(campaignTarget[i].reward_name)+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.text2_stroke_width+'px #'+dataBarcode.text2_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                ].join('\n')
                                            }}>
                                            </style>
                                            <span className='text1 _1'>ครบ</span>&nbsp;
                                            <span className='text2 _1'>{ userTarget[i] }</span>&nbsp;
                                            { 
                                            (i === 0) ? <span className='text1 _2'>แถว รับ</span> : <span className='text1 _3'>แถว รับ</span>
                                            }&nbsp;
                                            {
                                                (campaignTarget[i].reward_type === 'sfdc') ? 
                                                <React.Fragment>
                                                    <span className='text2 _2'>{ (campaignTarget[i].sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  || 0)} </span>&nbsp;
                                                    <span className='text1 _4'>คะแนน</span> 
                                                </React.Fragment>
                                                : 
                                                <span className='text2 _3'>
                                                    { campaignTarget[i].reward_name }
                                                </span>
                                            }
                                            
                                        </p>
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                    {
                        (dataUser.text_extra) ? 
                            <div className="App-text-extra">
                                <style dangerouslySetInnerHTML={{
                                    __html: [
                                        '.textx{', 
                                            'font-size: '+dataBarcode.textx_size+'rem;',
                                            'color: #'+dataBarcode.textx_color+';',
                                            'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                        '.textx:before {',
                                            'text-shadow: '+dataBarcode.textx_shadow_width+'px '+dataBarcode.textx_shadow_width+'px '+dataBarcode.textx_shadow_width+'px #'+dataBarcode.textx_shadow_color+';',
                                        '}',
                                        '.textx:before { content: "'+(dataUser.text_extra)+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.textx_stroke_width+'px #'+dataBarcode.textx_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                        ].join('\n')
                                    }}>
                                </style>
                                <span className="textx">{dataUser.text_extra}</span>
                            </div>
                        : null
                    }
                    
                    <div className="App-bar" id="app-bar" ref={barRef}>
                        <div className="App-bar-stick">
                            <div className="round-stick">
                                <div className="bar-group bar-group">
                                    <div id="bar-stick-green" className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                    <div id="bar-stick-yellow"  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                </div>
                                {/* {
                                    
                                    userTarget.map((item,i)=>{
                                        return(
                                            <div className={'bar-group bar-group-'+(i)} key={i}>
                                                <div id={'bar-stick-green-'+i} className="bar-stick _green" style={{width: barGreen+'px'}}></div>
                                                <div id={'bar-stick-yellow-'+i}  className="bar-stick _yellow" style={{width: barYellow+'px'}}></div>
                                            </div>)
                                        }
                                    )
                                } */}
                            </div>
                        </div>
                        <img src={bar} className="App-bar-border" alt="bar" />
                        {
                            campaignTarget.map((item,i)=>{
                                return (
                                    <div className="App-target-box" style={{left: targetPos * (i+1), width: appBar / 1.8, height: appBar / 1.8,}} key={i} >{
                                            (dataUser.checked >= dataUser.target[i]) ? 
                                                <img src={item.target_picture_reached} className="App-target" alt="target-reached" />
                                            :
                                                <img src={item.target_picture_not_reached} className="App-target" alt="target-not-reached" />   
                                        }
                                        
                                        {/* <img src={target1000} className="App-target" alt="target" /> */}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="App-status">
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text3{', 
                                    'font-size: '+dataBarcode.text3_size+'rem;',
                                    'color: #'+dataBarcode.text3_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text3:before {',
                                    'text-shadow: '+dataBarcode.text3_shadow_width+'px '+dataBarcode.text3_shadow_width+'px '+dataBarcode.text3_shadow_width+'px #'+dataBarcode.text3_shadow_color+';',
                                '}',
                                '.text3._1:before { content: "ตรวจแล้ว"; position: absolute; -webkit-text-stroke: '+dataBarcode.text3_stroke_width+'px #'+dataBarcode.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text3._2:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataBarcode.text3_stroke_width+'px #'+dataBarcode.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text3._3:before { content: "รอการตรวจ"; position: absolute; -webkit-text-stroke: '+dataBarcode.text3_stroke_width+'px #'+dataBarcode.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text3._4:before { content: "แถว"; position: absolute; -webkit-text-stroke: '+dataBarcode.text3_stroke_width+'px #'+dataBarcode.text3_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                                    
                                '.text4{', 
                                    'font-size: '+dataBarcode.text4_size+'rem;',
                                    'color: #'+dataBarcode.text4_color+';',
                                    'text-shadow: '+dataBarcode.text4_shadow_width+'px '+dataBarcode.text4_shadow_width+'px '+dataBarcode.text4_shadow_width+'px #'+dataBarcode.text4_shadow_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text4:before {',
                                    'text-shadow: '+dataBarcode.text4_shadow_width+'px '+dataBarcode.text4_shadow_width+'px '+dataBarcode.text4_shadow_width+'px #'+dataBarcode.text4_shadow_color+';',
                                '}',
                                '.text4._1:before { content: "'+(dataUser.checked)+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.text4_stroke_width+'px #'+dataBarcode.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',
                                '.text4._2:before { content: "'+(dataUser.pending)+'"; position: absolute; -webkit-text-stroke: '+dataBarcode.text4_stroke_width+'px #'+dataBarcode.text4_stroke_color+'; left: 0; top: 0; z-index: -1;}',

                                ].join('\n')
                            }}>
                        </style>
                        <div className="status-content">
                            <img src={approved} className="status-bullet" alt="approved" />
                            <span className="text3 _1">ตรวจแล้ว</span>&nbsp;
                            <span className="text4 _1">{dataUser.checked}</span>&nbsp;
                            <span className="text3 _2">แถว</span>
                        </div>
                        <div className="status-content">
                            <img src={pending} className="status-bullet" alt="pending" />
                            <span className="text3 _3">รอการตรวจ</span>&nbsp;
                            <span className="text4 _2">{dataUser.pending}</span>&nbsp;
                            <span className="text3 _4">แถว</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        <div className="App-error">
          <p>ข้อมูลระหว่าง campaign และ user ไม่ตรงกัน</p>
        </div>
    }

}

export default Barcode
