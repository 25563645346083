import React, { useEffect } from 'react'

// import background from '../assets/images/campaign1/bg.jpg';
// import header from '../assets/images/campaign1/header.png';
// import bar from '../assets/images/campaign1/bar.png';
// import target from '../assets/images/campaign1/target.png';
// import targetCurrent from '../assets/images/campaign1/Task-Stage-Active.png';
// import targetFinish from '../assets/images/campaign1/Task-Stage-Goal.png';
// import targetReached from '../assets/images/campaign1/Task-Stage-Pass.png';
// import targetUnReached from '../assets/images/campaign1/Task-Stage-Unactive.png';

function Task(props) {
    // const [isLoaded, setIsLoaded] = useState(false)
    // const [appPath, setAppPath] = useState(0)

    const { dataCampaign } = props;
    const dataTask = dataCampaign.campaign.task
    const dataUser = dataCampaign.user;

    // User data
    const userProgress = dataUser.progress;
    // const userProgress = 35;

    // Target data
    // const target = dataTask.target;
    const targetPin = dataTask.target.target;
    // const targetPin = 40;

    // Images data
    const background = dataTask.background_picture;
    const header = dataTask.headline_picture;
    const path = dataTask.path_picture;
    const targetReached = dataTask.reached_picture;
    const targetUnReached = dataTask.unreached_picture;
    const targetCurrent = dataTask.current_picture;
    const targetFinish = dataTask.finish_picture;

    const getAppPathPin = () => {
        let getAppPathPinArr = [];
        for (let i = 1; i <= targetPin; i++) {
            if(i === userProgress) {
                // State Current (Text group 5)
                // Check Finish
                if(i === targetPin) {
                    getAppPathPinArr.push(
                        <li key={i}>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text5{', 
                                        'font-size: '+dataTask.text5_size+'rem;',
                                        'color: #'+dataTask.text5_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                    
                                    ].join('\n')
                                }}>
                            </style>
                            {/* <div className="App-path-pin _current last-pin" style={{backgroundImage: 'url('+targetFinish+')'}}>
                            </div> */}
                            <div className="App-path-pin _current" style={{backgroundImage: 'url('+targetCurrent+')'}}>
                                <span className="text5">{i}</span>
                            </div>
                        </li>
                    )
                } else {
                    getAppPathPinArr.push(
                        <li key={i}>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text5{', 
                                        'font-size: '+dataTask.text5_size+'rem;',
                                        'color: #'+dataTask.text5_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                    
                                    ].join('\n')
                                }}>
                            </style>
                            <div className="App-path-pin _current" style={{backgroundImage: 'url('+targetCurrent+')'}}>
                                <span className="text5">{i}</span>
                            </div>
                        </li>
                    )
                }
            } else if(i < userProgress) {
                // State Reached  (Text group 4)
                getAppPathPinArr.push(
                    <li key={i}>
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text4{', 
                                    'font-size: '+dataTask.text4_size+'rem;',
                                    'color: #'+dataTask.text4_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                
                                ].join('\n')
                            }}>
                        </style>
                        <div className="App-path-pin _reached" style={{backgroundImage: 'url('+targetReached+')'}}>
                            <span className="text4">{i}</span>
                        </div>
                    </li>
                )
            } else {
                // State UnReached (Text group 6)
                // Check Finish
                if(i === targetPin) {
                    getAppPathPinArr.push(
                        <li key={i}>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text6{', 
                                        'font-size: '+dataTask.text6_size+'rem;',
                                        'color: #'+dataTask.text6_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                    
                                    ].join('\n')
                                }}>
                            </style>
                            {/* <div className="App-path-pin _unreached last-pin" style={{backgroundImage: 'url('+targetFinish+')'}}>
                            </div> */}
                            <div className="App-path-pin _unreached" style={{backgroundImage: 'url('+targetUnReached+')'}}>
                                <span className="text6">{i}</span>
                            </div>
                        </li>
                    )
                } else {
                    getAppPathPinArr.push(
                        <li key={i}>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.text6{', 
                                        'font-size: '+dataTask.text6_size+'rem;',
                                        'color: #'+dataTask.text6_color+';',
                                        'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                    
                                    ].join('\n')
                                }}>
                            </style>
                            <div className="App-path-pin _unreached" style={{backgroundImage: 'url('+targetUnReached+')'}}>
                                <span className="text6">{i}</span>
                            </div>
                        </li>
                    )
                }
            }
        }
        return getAppPathPinArr;
    }

    useEffect(() => {
        console.log(targetPin)
    }, [targetPin])

    return (
        <div className="App-task">
            <div className="App-background" style={{backgroundImage: 'url('+background+')'}}>
                <header className="App-header">
                    <img src={header} className="App-logo" alt="logo" />
                    <div className="App-content">
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.text1{', 
                                    'font-size: '+dataTask.text1_size+'rem;',
                                    'color: #'+dataTask.text1_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text2{', 
                                    'font-size: '+dataTask.text2_size+'rem;',
                                    'color: #'+dataTask.text2_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                '.text3{', 
                                    'font-size: '+dataTask.text3_size+'rem;',
                                    'color: #'+dataTask.text3_color+';',
                                    'position: relative; display: inline-flex; background: transparent; z-index: 0;}',
                                
                                ].join('\n')
                            }}>
                        </style>
                        <p>
                            <span className="text1">
                                { dataTask.text1_fixed }
                            </span>
                            <span className="text1">
                                { dataTask.text1_context }
                            </span>
                            <br/>
                            <span className="text2">
                                {
                                    (dataTask.target.reward_type === 'sfdc') ? 
                                        'รับ '+(dataTask.target.sfdc_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0)+ ' คะแนน ' 
                                    :
                                        'รับ '+dataTask.target.reward_name+' '
                                }
                            </span>
                            <br/>
                            <span className="text3">
                                { dataTask.text3_context }
                            </span>
                        </p>
                    </div>
                </header>
                <div className="App-path">
                    <div className="App-path-bg" style={{backgroundImage: 'url('+path+')'}}>
                        <ul className="App-path-list">
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.App-path-list li:last-child{', 
                                        'background-image: url('+targetFinish+');',
                                        'background-size: cover;',
                                        '}',
                                    '.App-path-list li:last-child .App-path-pin._current, .App-path-list li:last-child .App-path-pin._reached{', 
                                        'background-image: url('+targetCurrent+') !important;',
                                        'background-size: cover;',
                                        '}',
                                    '.App-path-list li:last-child .text4{', 
                                        'font-size: '+dataTask.text5_size+'rem;',
                                        'color: #'+dataTask.text5_color+';}',
                                    '.App-path-list li:last-child .text5{', 
                                        'font-size: '+dataTask.text5_size+'rem;',
                                        'color: #'+dataTask.text5_color+';}',
                                    ].join('\n')
                                }}>
                            </style>
                            { getAppPathPin() }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Task
